import React from "react";
import InexCard from "../InexCard";
import { TopBorder } from "../billingChoice/BillingCard";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import editIcon from "../../resources/images/svg/addressChoice/edit_3@1,5x.svg";
import deleteIcon from "../../resources/images/svg/addressChoice/trash_2@1,5x.svg";
import checkIcon from "../../resources/images/svg/CheckIcon.svg";
import InexButton from "../InexButton";
import { decodeString } from "../../hooks/decodeString";
import PriceFormater from "../PriceFormater";
import { useGetAddressCostQuery } from "../../store/api/subscribeTunnel";
import { useSelector } from "react-redux";
import { getSelectedBilling, getSelectedSubscriptions, getTunnel } from "../../store/reducers/subscribeSlice";
import { getAuthToken, getInreesId } from "../../store/reducers/authSlice";
import { getGiftCardData } from "../../store/reducers/giftCardSlice";

const AddressCard = ({ address, selected = false, onClick, onCostChanges, onDelete, onEdit, ...props }) => {
	const [cost, setCost] = React.useState(null);

	const inreesId = useSelector(getInreesId);
	const authToken = useSelector(getAuthToken);

	const tunnel = useSelector(getTunnel);
	const giftCardData = useSelector(getGiftCardData);
	const selectedSubscriptions = useSelector(getSelectedSubscriptions);
	const selectedBillingFrequency = useSelector(getSelectedBilling);

	const { data: addressCost } = useGetAddressCostQuery(
		{
			authToken,
			zone: address?.zone,
			idinexplore: inreesId,
			frequence: tunnel !== 2 ? selectedBillingFrequency.frequence : giftCardData?.commande.details[0].frequence,
			selectedSubscriptions: tunnel !== 2 ? selectedSubscriptions : giftCardData?.commande.details.map((e) => e.abo),
		},
		{
			skip: !address,
		}
	);

	const getCost = () => {
		const cost1 = parseInt(addressCost.frequence.montant);
		const cost2 = parseInt(tunnel !== 2 ? selectedBillingFrequency.montant : giftCardData?.commande.total);
		return cost1 - cost2;
	};

	React.useEffect(() => {
		if (addressCost && (selectedBillingFrequency || tunnel === 2)) {
			setCost(getCost());
		}
	}, [addressCost, selectedBillingFrequency, tunnel, giftCardData]);

	React.useEffect(() => {
		if (cost !== null) {
			onCostChanges && onCostChanges(cost);
		}
	}, [cost]);

	if (!address) return null;

	const addressStr = decodeString(address.address);
	const country = decodeString(address.pays);
	const city = decodeString(address.ville);

	const complement1 = decodeString(address.complement1);
	const complement2 = decodeString(address.complement2);

	return (
		<InexCard
			{...props}
			selected={selected}
			sx={{
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				height: "100%",
				"&:hover": {
					cursor: "pointer",
				},
				...props.sx,
			}}
			onClick={() => {
				onClick(getCost());
			}}
		>
			<TopBorder selected={selected} />
			<Box
				py={2.5}
				px={2.5}
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between ",
				}}
			>
				<Box
					pb={1.5}
					sx={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div>
						{cost && cost !== 0 && (
							<Tooltip
								title={`Ce ${
									cost > 0 ? "surcoût" : "moins-coût"
								} est lié à l'endroit où vous vous trouvez, ce sont d'éventuels frais d'envois dus à votre distance avec la France`}
							>
								<div>
									<PriceFormater
										price={cost}
										withTitle={false}
										withTimeFrame={false}
										withSign={true}
										priceProps={{
											sx: {
												background: (theme) => theme.palette.grey["300"],
												borderRadius: "25px",
												padding: (theme) => `${theme.spacing(0.25)} ${theme.spacing(1.25)}`,
												fontSize: 14,
											},
										}}
									/>
								</div>
							</Tooltip>
						)}
					</div>
					<Box>
						{onEdit && (
							<IconButton
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onEdit();
								}}
							>
								<img src={editIcon} alt={"editIcon"} />
							</IconButton>
						)}
						{onDelete && (
							<IconButton
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onDelete();
								}}
							>
								<img src={deleteIcon} alt={"deleteIcon"} />
							</IconButton>
						)}
					</Box>
				</Box>
				<Box pb={2}>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 20,
							overflow: "hidden",
						}}
					>
						{`${address.prenom} ${address.nom}`}
					</Typography>
				</Box>
				<Box pb={4}>
					<Typography
						textAlign={"left"}
						color={"secondary"}
						noWrap
						sx={{
							fontFamily: "Branding Medium",
							fontSize: 16,
							overflow: "hidden",
						}}
					>
						{`${addressStr}`}
					</Typography>
					{address.complement1 && address.complement1 !== "" && (
						<Typography
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding Medium",
								fontSize: 16,
							}}
						>
							{`${complement1}`}
						</Typography>
					)}
					{address.complement2 && address.complement2 !== "" && (
						<Typography
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding Medium",
								fontSize: 16,
							}}
						>
							{`${complement2}`}
						</Typography>
					)}
					<Typography
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Medium",
							fontSize: 16,
						}}
					>
						{`${address.cp} ${city}, ${country}`}
					</Typography>
				</Box>

				<Box>
					<InexButton
						text={"Sélectionner"}
						variant={selected ? "icon" : "text"}
						icon={checkIcon}
						textWithGradient={false}
						onClick={() => {
							onClick(getCost());
						}}
						sx={{
							padding: (theme) => theme.spacing(2),
							width: "100%",
							height: "50px",
							background: (theme) => (!selected ? "transparent" : theme.palette.grey["300"]),
							border: (theme) => (!selected ? `1px solid ${theme.palette.secondary.main}` : "none"),
							color: (theme) => theme.palette.secondary.main,
							"&:hover": {
								backgroundColor: "grey.300",
							},
						}}
					/>
				</Box>
			</Box>
		</InexCard>
	);
};

export default AddressCard;
