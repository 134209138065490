import React from "react";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import { Grid, Typography } from "@mui/material";
import InexCard from "../components/InexCard";
import { useDispatch } from "react-redux";
import { setTunnel } from "../store/reducers/subscribeSlice";
import InexButton from "../components/InexButton";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import { routesDefinitions } from "./RoutesDefinitions";
import { useNavigate } from "react-router";

const AlreadySubscribed = () => {
	useRedirectToLogin();
	useLogUserIn();

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
			<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
				<InexCard
					sx={{
						paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography color={"secondary"} textAlign={"center"} fontSize={20} fontFamily={"Branding Bold"}>
								Vous êtes déjà abonné(e) à toutes nos formules.
							</Typography>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
						<Grid item xs={12} sm={12} md={10} lg={6}>
							<InexButton
								text={"Offrir une carte cadeau"}
								textWithGradient={false}
								onClick={() => {
									dispatch(setTunnel(1));
									navigate(routesDefinitions["COUNTRY_CHOICE"].routerObj.path);
								}}
								sx={{
									marginTop: (theme) => theme.spacing(isMobile ? 3 : 5),
									width: "100%",
									height: "50px",
									background: "transparent",
									border: (theme) => `1px solid ${theme.palette.secondary.main}`,
									color: (theme) => theme.palette.secondary.main,
									"&:hover": {
										background: (theme) => theme.palette.grey[300],
									},
								}}
							/>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
						<Grid iten xs={0} sm={0} md={1} lg={3} />
						<Grid item xs={12} sm={12} md={10} lg={6}>
							<InexButton
								text={"Aller sur mon profil"}
								onClick={() => {
									window.location.href = "https://my.inexplore.com/profil";
								}}
								sx={{
									marginTop: (theme) => theme.spacing(2),
									width: "100%",
									height: "50px",
								}}
							/>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
					</Grid>
				</InexCard>
			</Grid>
		</Grid>
	);
};

export default AlreadySubscribed;
