import React from "react";
import { useSelector } from "react-redux";
import { getTunnel } from "../store/reducers/subscribeSlice";
import { routesDefinitions } from "../routes/RoutesDefinitions";

export const useStep = (pageName) => {
	const tunnel = useSelector(getTunnel);

	const route = routesDefinitions[pageName];
	if (!route) return -1;

	return route.stepNb - (tunnel !== 1 && route.stepNb > 3 ? 1 : 0);
};
