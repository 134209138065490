// GoogleTagManager.js
import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  const [path, setPath] = React.useState(window.location.pathname);
  useEffect(() => {
    // Conditionnez l'exécution du code Google Tag Manager en fonction de l'emplacement
      // Créez une fonction pour initialiser Google Tag Manager
      window.initGoogleTagManager = function() {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-1049373766');
        gtag('config', 'G-K0XK1HXR6E');
        gtag('config', 'UA-6779645-4');
        window.dataLayer.push({
			event: 'page_view',
			pagePath: path,
			pageTitle: document.title,
		});
      };

      // Chargez le script Google Tag Manager de manière asynchrone
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-1049373766';
      script.async = true;
      script.onload = window.initGoogleTagManager;
      document.body.appendChild(script);

      return () => {
        // Nettoyez le script lors du démontage du composant
        document.body.removeChild(script);
        delete window.initGoogleTagManager;
      };
    
  }, [path]);

  return null;
};

export default GoogleTagManager;