import React from "react";
import { Grid, Typography } from "@mui/material";
import InexAutoComplete from "../components/InexAutoComplete";
import { useLazyGetPaysQuery } from "../store/api/subscribeTunnel";
import { useNavigate } from "react-router";
import SubscriptionDetail from "../components/SubscriptionDetail";
import {
	getAllCountries,
	getSelectedCountry,
	setAllCountries,
	setSelectedCountry,
} from "../store/reducers/subscribeSlice";
import { useDispatch, useSelector } from "react-redux";
import BasicScreen from "../components/BasicScreen";
import { useRedirectToLanding } from "../hooks/subscription";
import InexCard from "../components/InexCard";
import { routesDefinitions } from "./RoutesDefinitions";
import { useStep } from "../hooks/useStep";

const CountryChoice = ({ ...props }) => {
	useRedirectToLanding();

	const stepNb = useStep("COUNTRY_CHOICE");

	// Country choice AutoComplete
	const [fetchPays, { isFetching }] = useLazyGetPaysQuery();

	const [open, setIsOpen] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const dataPays = useSelector(getAllCountries);

	React.useEffect(() => {
		if (open && options.length === 0) {
			fetchPays().then(({ data }) => {
				dispatch(setAllCountries(data.pays));
				setOptions(data.pays.map((e) => e.pays));
				setIsLoading(false);
			});
		}
	}, [open, options]);

	React.useEffect(() => {
		if (isFetching === true) setIsLoading(true);
	}, [isFetching]);

	const selectedCountry = useSelector(getSelectedCountry);

	const dispatch = useDispatch();
	const setSelectedCountryValue = (value) => {
		dispatch(setSelectedCountry(value));
	};

	return (
		<BasicScreen
			step={stepNb}
			nextPath={routesDefinitions["SUBSCRIPTION_CHOICE"].routerObj.path}
			canGoNext={selectedCountry !== null}
		>
			<InexCard
				sx={{
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<Typography
					variant={"h4"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 20,
					}}
				>
					{"Sélectionnez votre pays :"}
				</Typography>
				<InexAutoComplete
					label={"Pays"}
					options={options}
					sx={{
						marginTop: (theme) => theme.spacing(3),
					}}
					value={selectedCountry?.pays}
					loadingText={"Chargement des pays disponibles..."}
					onOpen={() => setIsOpen(true)}
					onClose={() => setIsOpen(false)}
					loading={isFetching || isLoading}
					onChange={(event, val) => {
						if (dataPays.current !== null) {
							if (val !== null) {
								const selectedCountry = dataPays.find((e) => e.pays === val);
								if (selectedCountry) setSelectedCountryValue(selectedCountry);
							} else {
								setSelectedCountryValue(val);
							}
						}
					}}
				/>
			</InexCard>
		</BasicScreen>
	);
};

export default CountryChoice;
