import { api } from "./rtkApi";
import { encode } from "querystring";
import Cookies from "universal-cookie/es6";

export const searchApi = api.injectEndpoints({
	endpoints: (build) => ({
		getAuthKey: build.query({
			query: ({ inreesId, inreesToken, ts }) => {
				const cookies = new Cookies();
				const affiliation = cookies.get("affiliation") || null;

				return {
					method: "POST",
					url: `/user/cle?ts=${ts}`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: inreesId,
						token: inreesToken,
						affiliation
					}),
				};
			},
		}),
		getUserAddresses: build.query({
			query: ({ idinexplore, authToken }) => {
				return {
					method: "POST",
					url: `/user/list-adresses`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
					}),
				};
			},
		}),
		createUserAddress: build.query({
			query: ({
				authToken,
				idinexplore,
				civilite,
				nom,
				prenom,
				address,
				complement1,
				complement2,
				ville,
				cp,
				idpays,
			}) => {
				return {
					method: "POST",
					url: `/user/ajouter-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						civilite: civilite === false ? "" : civilite,
						nom,
						prenom,
						adresse: address,
						complement1,
						complement2,
						ville,
						cp,
						idpays,
					}),
				};
			},
		}),
		editUserAddress: build.query({
			query: ({
				authToken,
				idinexplore,
				civilite,
				nom,
				prenom,
				address,
				idadresse,
				complement1,
				complement2,
				ville,
				cp,
				idpays,
			}) => {
				return {
					method: "POST",
					url: `/user/modifier-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						civilite: !["Madame", "Monsieur"].includes(civilite) ? "" : civilite,
						nom,
						prenom,
						adresse: address,
						idadresse: idadresse,
						complement1,
						complement2,
						ville,
						cp,
						idpays,
					}),
				};
			},
		}),
		deleteAddress: build.query({
			query: ({ authToken, idinexplore, address }) => {
				return {
					method: "POST",
					url: `/user/supprimer-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						idadresse: address.idadresse,
					}),
				};
			},
		}),
		getSepa: build.query({
			query: ({ authToken, idinexplore }) => {
				return {
					method: "POST",
					url: `/user/get-sepa`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
					}),
				};
			},
		}),
		getAbos: build.query({
			query: ({ authToken, idinexplore }) => {
				return {
					method: "POST",
					url: `/abo/get-abos`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
					}),
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetAuthKeyQuery,
	useLazyGetAuthKeyQuery,
	useGetUserAddressesQuery,
	useLazyGetUserAddressesQuery,
	useCreateUserAddressQuery,
	useLazyCreateUserAddressQuery,
	useEditUserAddressQuery,
	useLazyEditUserAddressQuery,
	useDeleteAddressQuery,
	useLazyDeleteAddressQuery,
	useGetSepaQuery,
	useLazyGetSepaQuery,
	useGetAbosQuery,
	useLazyGetAbosQuery,
} = searchApi;
