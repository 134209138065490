import React from "react";
import { Box, styled, Typography } from "@mui/material";

const Container = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		minWidth: "55px",
		margin: "auto",
	};
});

const SubscriptionTypeIcon = ({ icon, text, ...props }) => {
	return (
		<Container {...props}>
			{icon}
			<Typography
				color={"secondary"}
				sx={{
					marginTop: (theme) => theme.spacing(0.5),
					fontFamily: "Branding Medium",
				}}
			>
				{text}
			</Typography>
		</Container>
	);
};

export default SubscriptionTypeIcon;
