import React, { useEffect, useState } from "react";
import InexploreWhiteLogo from "../../resources/images/svg/footer/inexplore-logo-white.svg";
import InreesWhiteLogo from "../../resources/images/svg/footer/logo-inrees-white.svg";
import { ReactComponent as Send } from "../../resources/images/svg/footer/send.svg";
import { ReactComponent as Question } from "../../resources/images/svg/footer/question-mark-circle.svg";
import { ReactComponent as Phone } from "../../resources/images/svg/footer/phone.svg";
import { ReactComponent as Mail } from "../../resources/images/svg/footer/mail.svg";

import moment from "moment";
import { Alert } from "@mui/material";
import { useGetFooterQuery } from "../../store/api/footer";

export function ContactFooter(props) {
	const [open, setOpen] = useState(null);

	const isOpen = () => {
		const day = moment().isoWeekday();
		const fullTime = moment().hour() + moment().minutes() / 60;
		const daymonth = moment().format("D-M");
		const feries = ["25-12","26-12","29-12","1-1","14-7","11-11"];

		if (day <= 5 && !feries.includes(daymonth)) {
			if (fullTime >= 9.5 && fullTime < 17) {
				if (fullTime >= 12.5 && fullTime < 14) {
					setOpen(false);
				} else {
					setOpen(true);
				}
			} else {
				setOpen(false);
			}
		} else {
			setOpen(false);
		}
	};

	useEffect(() => {
		isOpen();
		let openService = setInterval(() => {
			isOpen();
		}, 300000);
		return () => clearInterval(openService);
	}, []);

	return (
		<div className="contacts">
			<ul>
				<li className="sav" key={"status"}>
					<p className="status">
						Service client
						{open !== null && <span className={open ? "open" : "close"}>{open ? "Ouvert" : "Fermé"}</span>}
					</p>
					<p>Du lundi au vendredi</p>
					<p>De 09:00 à 12:30 - 14:00 à 17:00</p>
				</li>
			</ul>
			<ul>
				<li key={"FAQ"}>
					<a href="https://www.inrees.com/FAQ/" target="_blank" rel="noreferrer">
						Foire aux questions
						<span>
							<Question className="icon" />
						</span>
					</a>
				</li>
			</ul>
			<ul>
				<li key={"email"}>
					<a href="https://www.inrees.com/Contact" target="_blank" rel="noreferrer">
						Email
						<span>
							<Mail className="icon" />
						</span>
					</a>
				</li>
			</ul>
			<ul>
				<li key={"tel"}>
					{open && (
						<a className="phone" target="_blank" rel="noreferrer" href="tel:+33178905956">
							Tél: +33 1 78 90 59 56
							<span>
								<Phone className="icon" />
							</span>
						</a>
					)}
				</li>
			</ul>
		</div>
	);
}

export function Errors(props) {
	return (
		<div className={props.className} style={{ backgroundColor: props.backgroundColor }}>
			<Alert color={props.textColor} />
			<span style={{ color: props.textColor }}>{props.message}</span>
		</div>
	);
}

export default function Footer(props) {
	const [footer, setFooter] = useState(null);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setError] = useState({
		status: false,
		message: "",
	});

	const now = moment().format("YYYY");

	const { data, isFetching } = useGetFooterQuery();

	useEffect(() => {
		if (data) {
			setFooter(data.footer);
		}
	}, [data]);

	const handleChangeEmail = (value) => {
		setError({ status: false });
		setEmail(value);
	};

	const checkEmail = (val) => {
		const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
		return reg.test(val);
	};

	const onNewsletterFormSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (email === "") {
			setError({
				status: true,
				message: "Veuillez renseigner votre adresse email",
			});
		} else {
			const isEmailValid = checkEmail(email);
			if (!isEmailValid) {
				setError({
					status: true,
					message: "Veuillez renseigner une adresse email valide",
				});
			} else {
				setLoading(true);

				window.location.href = `https://login.inexplore.com/newsletter?email=${email}&referrer=${window.location.href}`;
				setEmail("");
			}
		}
	};

	return (
		<footer>
			<div className="informations">
				<div className="inexplore-logo">
					<a href="https://inexplore.com/" target="_blank" rel="noreferrer">
						<img src={InexploreWhiteLogo} className="logo" alt="logo Inexploré" />
					</a>
					<p>À la croisée des mondes</p>
				</div>
				{footer !== null && (
					<div className="socials">
						<div className="social-links">
							<p>{footer.reseaux.titre}</p>
							<ul>
								{footer.reseaux.items.map((item) => {
									return (
										<li key={item.titre}>
											<a href={item.lien} target="_blank">
												<img src={item.picto} className="icon" alt={item.titre}></img>
												{item.titre}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
						<div className="newsletter">
							<p>Inscrivez-vous à notre newsletter</p>
							<form className={!error.status ? "form" : "form input-border-error-newsletter-footer"}>
								<input
									type="email"
									placeholder="Votre adresse email"
									value={email}
									onChange={(e) => handleChangeEmail(e.target.value)}
									style={{
										paddingLeft: 10
									}}
								/>
								<button className="btn-footer-newsletter" onClick={(e) => onNewsletterFormSubmit(e)}>
									<Send />
								</button>
							</form>
							{error.status && (
								<Errors
									className="error-authform"
									backgroundColor={"transparent"}
									textColor={"#e94b32"}
									message={error.message}
								/>
							)}
						</div>
					</div>
				)}
				{footer !== null && (
					<div className="links">
						{footer.content.map((content, i) => {
							return (
								<ul>
									<p>{content.titre}</p>
									{content.items.map((item, n) => {
										return (
											<li key={item.titre}>
												<a
													href={item.lien}
													target="_blank"
													className={i === 1 && n === 0 && "pdf-link"}
												>
													{item.titre}
												</a>
											</li>
										);
									})}
								</ul>
							);
						})}
						<ul>
							<p>{footer.applications.titre}</p>
							{footer.applications.items.map((item) => {
								return (
									<li key={item.titre}>
										{item.titre}
										<ul>
											{item.plateforme.map((key, n) => {
												return (
													<li key={n + key.lien}>
														<a href={key.lien} target="_blank">
															<img src={key.picto} className="icon"></img>
														</a>
													</li>
												);
											})}
										</ul>
									</li>
								);
							})}
						</ul>
					</div>
				)}
				<div className="copyright">
					<p>Inexploré est édité par l'INREES - Copyright © 2007 - {now} - Tous droits réservés</p>
					<a href="https://inrees.com/" target="_blank" rel="noreferrer">
						<img src={InreesWhiteLogo} alt="Inrees" />
					</a>
				</div>
			</div>
			<ContactFooter />
		</footer>
	);
}
