import React from "react";
import { Box, styled, Typography } from "@mui/material";
import PriceFormater from "../PriceFormater";
import InexButton from "../InexButton";
import checkIcon from "../../resources/images/svg/CheckIcon.svg";
import InexCard from "../InexCard";

export const TopBorder = styled(Box)(({ theme, selected }) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		borderTop: `10px solid ${theme.palette.secondary.main}`,
		pointerEvents: "none",
		zIndex: 1,
		opacity: selected === true ? 1 : 0,
		transition: "opacity 0.3s",
	};
});

const BillingCard = ({ billingObj, selected = false, onClick, ...props }) => {
	return (
		<InexCard
			selected={selected}
			sx={{
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={onClick}
			{...props}
		>
			<TopBorder selected={selected} />
			<Box
				sx={{
					paddingLeft: (theme) => theme.spacing(2),
					paddingRight: (theme) => theme.spacing(2),
					paddingTop: (theme) => theme.spacing(5),
					paddingBottom: (theme) => theme.spacing(2),
					backgroundColor: "rgba(22,42,65,0.03)",
				}}
			>
				<Typography
					variant={"h4"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding Bold",
						fontSize: 24,
					}}
				>
					{billingObj.titre}
				</Typography>
				<Typography
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding Medium",
						fontSize: 14,
						height: "21px",
					}}
				>
					{billingObj.minititre ? billingObj.minititre : ""}
				</Typography>
			</Box>
			<Box
				sx={{
					height: "36px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: billingObj.promo && billingObj.promo !== "" ? "#F3C34F" : "transparent",
				}}
			>
				<Typography
					sx={{
						fontSize: 16,
						color: (theme) => theme.palette.secondary.main,
					}}
				>
					{billingObj.promo && billingObj.promo !== "" ? billingObj.promo : ""}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					padding: (theme) => theme.spacing(2),
				}}
			>
				<Typography
					variant={"h4"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding Bold",
						fontSize: 24,
					}}
				>
					<PriceFormater price={billingObj.montant} period={billingObj.titre} withTitle={false} withTimeFrame={false} />
				</Typography>
				<Typography
					variant={"h6"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding Medium",
						fontSize: 14,
						height: "25px",
					}}
				>
					{billingObj.description}
				</Typography>
				<Typography
					variant={"h4"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding Medium",
						fontSize: 14,
						height: "25px",
					}}
				>
					{billingObj.remarque}
				</Typography>
				<InexButton
					text={"Sélectionner"}
					variant={selected ? "icon" : "text"}
					icon={checkIcon}
					textWithGradient={false}
					onClick={onClick}
					sx={{
						marginTop: (theme) => theme.spacing(4),
						width: "100%",
						height: "50px",
						background: (theme) => (!selected ? "transparent" : theme.palette.grey["300"]),
						border: (theme) => (!selected ? `1px solid ${theme.palette.secondary.main}` : "none"),
						color: (theme) => theme.palette.secondary.main,
						"&:hover": {
							backgroundColor: "grey.300",
						},
					}}
				/>
			</Box>
		</InexCard>
	);
};

export default BillingCard;
