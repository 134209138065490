import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	tunnel: null,
	selectedCountry: {idpays: 61, pays: "France (métropolitaine)", zone: 1},
	allCountries: null,
	selectedSubscriptions: [],
	selectedBilling: null,
	selectedAddress: null,
	selectedAddressCost: null,
	applyTip: false,
	tipAmount: 2,
	tipPeriod: 1,
	paymentMethod: null,
	giftMethod: null,
	giftFirstName: null,
	giftLastName: null,
	giftEmail: null,
	giftPhone: null,
	order: null,
	promo: null,
};

const slice = createSlice({
	name: "subscribe",
	initialState,
	reducers: {
		setTunnel(state, action) {
			if (state.tunnel !== action.payload) {
				state.selectedCountry = initialState.selectedCountry;
				state.allCountries = initialState.allCountries;
				state.selectedSubscriptions = initialState.selectedSubscriptions;
				state.selectedBilling = initialState.selectedBilling;
				state.selectedAddress = initialState.selectedAddress;
				state.selectedAddressCost = initialState.selectedAddressCost;
				state.applyTip = initialState.applyTip;
				state.tipAmount = initialState.tipAmount;
				state.tipPeriod = initialState.tipPeriod;
				state.paymentMethod = initialState.paymentMethod;
				state.giftMethod = initialState.giftMethod;
				state.giftFirstName = initialState.giftFirstName;
				state.giftLastName = initialState.giftLastName;
				state.giftEmail = initialState.giftEmail;
				state.giftPhone = initialState.giftPhone;
				state.order = initialState.order;
				state.promo = initialState.promo;
			}
			state.tunnel = action.payload;
		},
		setSelectedCountry(state, action) {
			state.selectedCountry = action.payload;
		},
		setAllCountries(state, action) {
			state.allCountries = action.payload;
		},
		addSubscription(state, action) {
			if (!state.selectedSubscriptions) state.selectedSubscriptions = [];
			if (state.selectedSubscriptions?.findIndex((e) => e.idabo === action.payload.idabo) === -1)
				state.selectedSubscriptions?.push(action.payload);
		},
		removeSubscription(state, action) {
			if (!state.selectedSubscriptions) state.selectedSubscriptions = [];
			if (state.selectedSubscriptions?.findIndex((e) => e.idabo === action.payload.idabo) !== -1)
				state.selectedSubscriptions = state.selectedSubscriptions?.filter((e) => e.idabo !== action.payload.idabo);
		},
		setSelectedBilling(state, action) {
			state.selectedBilling = action.payload;
			if (action.payload?.titre !== "Mensuel") {
				state.tipAmount = 10;
			}
		},
		setSelectedAddress(state, action) {
			state.selectedAddress = action.payload;
		},
		setSelectedAddressCost(state, action) {
			state.selectedAddressCost = action.payload;
		},
		setApplyTip(state, action) {
			state.applyTip = action.payload;
		},
		setTipAmount(state, action) {
			state.tipAmount = action.payload;
		},
		setTipPeriod(state, action) {
			state.tipPeriod = action.payload;
		},
		setPaymentMethod(state, action) {
			state.paymentMethod = action.payload;
		},

		setGiftMethod(state, action) {
			state.giftMethod = action.payload;
		},
		setGiftFirstName(state, action) {
			state.giftFirstName = action.payload;
		},
		setGiftLastName(state, action) {
			state.giftLastName = action.payload;
		},
		setGiftEmail(state, action) {
			state.giftEmail = action.payload;
		},
		setGiftPhone(state, action) {
			state.giftPhone = action.payload;
		},
		resetSubscriptionStore(state, action) {
			state.selectedCountry = initialState.selectedCountry;
			state.allCountries = initialState.allCountries;
			state.selectedSubscriptions = initialState.selectedSubscriptions;
			state.selectedBilling = initialState.selectedBilling;
			state.selectedAddress = initialState.selectedAddress;
			state.selectedAddressCost = initialState.selectedAddressCost;
			state.applyTip = initialState.applyTip;
			state.tipAmount = initialState.tipAmount;
			state.tipPeriod = initialState.tipPeriod;
			state.paymentMethod = initialState.paymentMethod;
			state.giftMethod = initialState.giftMethod;
			state.giftFirstName = initialState.giftFirstName;
			state.giftLastName = initialState.giftLastName;
			state.giftEmail = initialState.giftEmail;
			state.giftPhone = initialState.giftPhone;
			state.order = initialState.order;
			state.promo = initialState.promo;
		},
		setOrder(state, action) {
			state.order = action.payload
		},
		setPromo(state, action) {
			state.promo = action.payload
		}
	},
});

export default slice.reducer;
export const {
	setTunnel,
	setSelectedCountry,
	setAllCountries,
	addSubscription,
	removeSubscription,
	setSelectedBilling,
	setSelectedAddress,
	setSelectedAddressCost,
	setApplyTip,
	setTipAmount,
	setTipPeriod,
	setPaymentMethod,

	setGiftMethod,
	setGiftFirstName,
	setGiftLastName,
	setGiftEmail,
	setGiftPhone,
	resetSubscriptionStore,
	setOrder,
	setPromo
} = slice.actions;

export const getTunnel = (state) => state.subscribe.tunnel;

export const getSelectedCountry = (state) => state.subscribe.selectedCountry;

export const getAllCountries = (state) => state.subscribe.allCountries;

export const getSelectedSubscriptions = (state) => state.subscribe.selectedSubscriptions;

export const getSelectedBilling = (state) => state.subscribe.selectedBilling;

export const getSelectedAddress = (state) => state.subscribe.selectedAddress;

export const getSelectedAddressCost = (state) => state.subscribe.selectedAddressCost;

export const getApplyTip = (state) => state.subscribe.applyTip;

export const getTipAmount = (state) => state.subscribe.tipAmount;

export const getTipPeriod = (state) => state.subscribe.tipPeriod;

export const getPaymentMethod = (state) => state.subscribe.paymentMethod;

export const getGiftMethod = (state) => state.subscribe.giftMethod;
export const getGiftFirstName = (state) => state.subscribe.giftFirstName;
export const getGiftLastName = (state) => state.subscribe.giftLastName;
export const getGiftEmail = (state) => state.subscribe.giftEmail;
export const getGiftPhone = (state) => state.subscribe.giftPhone;

export const getOrder = (state) => state.subscribe.order;
export const getPromo = (state) => state.subscribe.promo;
