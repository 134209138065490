import React from "react";
import SubscriptionDetail from "../components/SubscriptionDetail";
import { Button, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import InexButton from "../components/InexButton";
import BasicScreen from "../components/BasicScreen";
import { routesDefinitions } from "./RoutesDefinitions";
import {
	useRedirectToAlreadySubscribed,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice,
	useRedirectToDeliveryFormat,
	useRedirectToLanding,
	useRedirectToSubscriptionChoice
} from "../hooks/subscription";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import AddressCard from "../components/addressChoice/AddressCard";
import { useGetUserAddressesQuery, useLazyDeleteAddressQuery } from "../store/api/user";
import { getSelectedAddress, setSelectedAddress, setSelectedAddressCost, setPromo } from "../store/reducers/subscribeSlice";
import { useStep } from "../hooks/useStep";
import InexCard from "../components/InexCard";
import AddressCreateModal from "../components/addressChoice/AddressCreateModal";
import AddressDeleteConfirmDialog from "../components/addressChoice/AddressDeleteConfirmDialog";

const AddressChoice = () => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAlreadySubscribed();

	useRedirectToLogin();
	useLogUserIn();

	const stepNb = useStep("ADDRESS_CHOICE");

	const inreesId = useSelector(getInreesId);
	const authToken = useSelector(getAuthToken);
	const [addresses, setAddresses] = React.useState(null);

	const [deleteAddress] = useLazyDeleteAddressQuery();

	const { data, isFetching, refetch } = useGetUserAddressesQuery(
		{
			idinexplore: inreesId,
			authToken,
		},
		{
			skip: !inreesId || !authToken,
		}
	);

	React.useEffect(() => {
		if (data) setAddresses(data.infos);
	}, [data]);

	const selectedAddress = useSelector(getSelectedAddress);

	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(setPromo(null));
	},[])
	
	const setSelectedAddressInStore = (value) => {
		dispatch(setSelectedAddress(value));
	};
	const setSelectedAddressCostInStore = (value) => {
		dispatch(setSelectedAddressCost(value));
	};

	React.useEffect(() => {
		if (addresses && selectedAddress && addresses.findIndex((e) => e.idadresse === selectedAddress.idadresse) === -1) {
			setSelectedAddressInStore(null);
			setSelectedAddressCostInStore(null);
		}
	}, [selectedAddress, addresses]);

	const [openModal, setOpenModal] = React.useState(false);

	const handleClose = (withRefresh = false) => {
		setOpenModal(false);
		withRefresh === true && handleRefetch();
	};

	const handleRefetch = () => {
		refetch();
	};

	const [addressToDelete, setAddressToDelete] = React.useState(null);

	return (
		<BasicScreen
			step={stepNb}
			nextPath={routesDefinitions["TIP_CHOICE"].routerObj.path}
			canGoNext={selectedAddress !== null}
		>
			<Typography
				variant={"h4"}
				textAlign={"left"}
				color={"secondary"}
				sx={{
					fontFamily: "Branding Bold",
					fontSize: 20,
				}}
			>
				{"Sélectionnez votre adresse :"}
			</Typography>
			<Grid container spacing={3} my={3} direction={"row"} wrap={"wrap"}>
				{isFetching === true || addresses === null ? (
					<>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"250px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"250px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"250px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
					</>
				) : (
					<>
						{addresses?.map((element, index) => {
							return (
								<Grid key={index} item xs={12} sm={6} md={6} lg={4}>
									<AddressCard
										address={element}
										selected={selectedAddress ? element.idadresse === selectedAddress?.idadresse : false}
										onClick={(cost) => {
											setSelectedAddressInStore(element);
											setSelectedAddressCostInStore(cost);
										}}
										onEdit={() => {
											setOpenModal(element);
										}}
										onDelete={() => {
											setAddressToDelete(element);
										}}
										onCostChanges={(cost) => {
											const isSelected = selectedAddress
												? element.idadresse === selectedAddress?.idadresse
												: element.default === 1;
											if (isSelected) setSelectedAddressCostInStore(cost);
										}}
									/>
								</Grid>
							);
						})}
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<InexCard
								sx={{
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
								onClick={() => {
									setOpenModal(true);
								}}
							>
								<Typography
									color={"secondary"}
									fontSize={20}
									textAlign={"center"}
									sx={{
										userSelect: "none",
									}}
								>
									Ajouter une nouvelle adresse
								</Typography>
								<IconButton
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setOpenModal(true);
									}}
									size={"large"}
									sx={{
										marginTop: 3,
										width: 75,
										height: 75,
										color: (theme) => theme.palette.secondary.main,
										backgroundColor: (theme) => theme.palette.grey[300],
									}}
								>
									+
								</IconButton>
							</InexCard>
						</Grid>
					</>
				)}
			</Grid>
			<AddressCreateModal
				open={!!openModal}
				address={openModal}
				onClose={(withRefresh) => {
					handleClose(withRefresh);
				}}
			/>
			<AddressDeleteConfirmDialog
				open={!!addressToDelete}
				onClose={(confirm = false) => {
					if (confirm === true) {
						deleteAddress({
							authToken,
							idinexplore: inreesId,
							address: addressToDelete,
						}).then(() => {
							handleRefetch();
						});
					}
					setAddressToDelete(null);
				}}
			/>
		</BasicScreen>
	);
};

export default AddressChoice;
