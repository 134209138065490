import React from "react";
import BasicScreen from "../components/BasicScreen";
import {
	useRedirectToAddressChoice, useRedirectToAlreadySubscribed,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice,
	useRedirectToDeliveryFormat,
	useRedirectToLanding,
	useRedirectToSubscriptionChoice
} from "../hooks/subscription";
import { routesDefinitions } from "./RoutesDefinitions";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import { Box, Collapse, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import InexCard from "../components/InexCard";
import InexSwitch from "../components/subscriptionChoice/InexSwitch";

import ethicalIcon from "../resources/images/svg/tipChoice/EhticalIcon.png";
import trustIcon from "../resources/images/svg/tipChoice/TrustIcon.png";
import ecosystemIcon from "../resources/images/svg/tipChoice/EcosystemIcon.png";
import loveIcon from "../resources/images/svg/tipChoice/LoveIcon.png";
import generalInterestIcon from "../resources/images/svg/tipChoice/GeneralInterestIcon.png";
import TipArgumentCard from "../components/tipChoice/TipArgumentCard";
import InexTextField from "../components/InexTextField";

import dropdownIcon from "../resources/images/svg/dropdown_arrow.svg";
import dropdownIconDisabled from "../resources/images/svg/dropdown_arrow_disabled.svg";
import { useIsMobile } from "../hooks/responsive";
import InexAutoComplete from "../components/InexAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
	getApplyTip,
	getSelectedBilling,
	getTipAmount,
	getTipPeriod,
	getTunnel,
	setApplyTip,
	setTipAmount,
	setTipPeriod,
} from "../store/reducers/subscribeSlice";
import { useStep } from "../hooks/useStep";
import { getGiftCardData } from "../store/reducers/giftCardSlice";

const argumentsList = [
	{
		icon: ethicalIcon,
		title: "Éthique",
		subTitle:
			"Nous vous garantissons une expérience sans publicité invasive. La sécurité et la confidentialité de vos données personnelles sont nos priorités.",
	},
	{
		icon: trustIcon,
		title: "Label de confiance",
		subTitle:
			"Pour que les informations que nous partageons participent à l’éveil du monde, nos investigations journalistiques sont soumises à un regard critique très exigeant.",
	},
	{
		icon: ecosystemIcon,
		title: "À l’image d’un écosystème",
		subTitle:
			"Notre planète qui est un trésor à protéger est notre source d’inspiration. L’organisation de notre entreprise est ancrée dans ce concept de biosphère.",
	},
	{
		icon: loveIcon,
		title: "Tournée vers les abonnés",
		subTitle:
			"Pour permettre au plus grand nombre d’accéder à nos contenus, nous nous engageons à l’excellence dans notre travail ainsi qu’au maintien de prix abordables et justes.",
	},
	{
		icon: generalInterestIcon,
		title: "D’intérêt général",
		subTitle:
			"Lorsque les expériences se veulent bouleversantes, nous proposons à ceux qui les vivent une écoute et des conseils gratuits prodigués par notre réseau de professionnels de santé.",
	},
];

const TipChoice = () => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAddressChoice();
	useRedirectToAlreadySubscribed();

	useRedirectToLogin();
	useLogUserIn();

	const stepNb = useStep("TIP_CHOICE");

	const isMobile = useIsMobile();
	const dispatch = useDispatch();

	const [collapseOpen, setCollapseOpen] = React.useState(!isMobile);

	const applyTip = useSelector(getApplyTip);
	const setApplyTipInStore = (value) => {
		dispatch(setApplyTip(value));
	};

	const tipAmount = useSelector(getTipAmount);
	const setTipAmountInStore = (value) => {
		dispatch(setTipAmount(value));
	};

	const tipPeriod = useSelector(getTipPeriod);
	const setTipPeriodInStore = (value) => {
		dispatch(setTipPeriod(value));
	};

	const disabled = React.useMemo(() => {
		return !applyTip;
	}, [applyTip]);

	const tunnel = useSelector(getTunnel);
	const giftCardData = useSelector(getGiftCardData);
	const selectedBilling = useSelector(getSelectedBilling);

	const options = React.useMemo(() => {
		let timeFrameVal;
		let title = selectedBilling?.titre;
		if (tunnel === 2) {
			title = giftCardData.commande.details[0].titre;
		}
		switch (title) {
			case "Annuel":
				timeFrameVal = "année";
				break;
			case "Semestriel":
				timeFrameVal = "semestre";
				break;
			case "Trimestriel":
				timeFrameVal = "trimestre";
				break;
			default:
				timeFrameVal = "mois";
				break;
		}
		//return ["Seulement pour cette fois", `Chaque ${timeFrameVal}`];
		return ["Seulement pour cette fois", "Chaque mois", "Chaque année"];
	}, [selectedBilling, tunnel, giftCardData]);

	return (
		<BasicScreen
			step={stepNb}
			nextPath={routesDefinitions["PAYMENT"].routerObj.path}
			canGoNext={!(applyTip === true && (tipAmount < 1 || tipPeriod === -1))}
		>
			<InexCard
				sx={{
					borderRadius: "4px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: isMobile ? "space-between" : "flex-start",
						flexDirection: "row",
						marginBottom: (theme) => theme.spacing(4),
					}}
				>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 20,
						}}
					>
						{"Je souhaite faire un don à l'INREES"}
					</Typography>
					<InexSwitch
						checked={applyTip}
						onClick={() => {
							setApplyTipInStore(!applyTip);
						}}
						sx={{
							marginLeft: (theme) => theme.spacing(3),
						}}
					/>
				</Box>
				<Grid container flexDirection={"row"} alignItems={"center"} wrap={"wrap"} spacing={2}>
					<Grid item xs={12} md={5}>
						<InexTextField
							label={"Montant"}
							disabled={disabled}
							value={disabled ? "" : tipAmount}
							onChange={(e) => {
								const regex = /^[0-9\b]+$/;
								if (e.target.value === "" || regex.test(e.target.value)) {
									setTipAmountInStore(e.target.value);
								}
							}}
							formControlSx={{
								minWidth: "100%",
								width: "100%",
							}}
							startAdornment={
								<InputAdornment
									position={"start"}
									sx={{
										marginTop: "17px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "flex-end",
										}}
									>
										<Typography color={disabled ? "grey.300" : "secondary"}>€</Typography>
									</Box>
								</InputAdornment>
							}
							endAdornment={
								<InputAdornment position={"end"}>
									<Box
										ml={2}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "column",
										}}
									>
										<IconButton
											size={"small"}
											onClick={() => {
												setTipAmountInStore(tipAmount > 0 ? tipAmount * 2 : 1);
											}}
										>
											<img
												src={disabled ? dropdownIconDisabled : dropdownIcon}
												alt={"dropDown"}
												style={{
													width: "10px",
													height: "10px",
													transform: "rotateX(180deg)",
												}}
											/>
										</IconButton>
										<IconButton
											size={"small"}
											onClick={() => {
												setTipAmountInStore(tipAmount > 1 ? tipAmount / 2 : 0);
											}}
										>
											<img
												src={disabled ? dropdownIconDisabled : dropdownIcon}
												alt={"dropDown"}
												style={{
													width: "10px",
													height: "10px",
													filter: "grayscale(1)",
												}}
											/>
										</IconButton>
									</Box>
								</InputAdornment>
							}
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<InexAutoComplete
							label={"Périodicité"}
							options={options}
							value={disabled ? null : options[tipPeriod]}
							disabled={disabled}
							sx={{
								background: "transparent",
								".MuiInputBase-root.Mui-disabled": {
									background: "transparent",
									backgroundColor: "transparent !important",
								},
							}}
							onChange={(event, val) => {
								setTipPeriodInStore(options.findIndex((e) => e === val));
							}}
						/>
					</Grid>
				</Grid>
				<Grid container flexDirection={"column"} mt={4}>
					<Grid
						item
						container
						wrap={"nowrap"}
						flexDirection={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Typography
							variant={"h5"}
							textAlign={"left"}
							color={"grey.500"}
							sx={{
								fontFamily: "Branding Bold",
								fontSize: 16,
							}}
						>
							{"Pourquoi faire un don à l'INREES ?"}
						</Typography>
						{isMobile && (
							<IconButton
								onClick={() => {
									setCollapseOpen(!collapseOpen);
								}}
								size={"medium"}
								sx={{
									width: "30px",
									height: "30px",
								}}
							>
								<img
									src={dropdownIcon}
									alt={"icon"}
									style={{
										transform: collapseOpen ? "rotate(180deg)" : "rotate(0deg)",
										transition: "rotate 0.5s",
									}}
								/>
							</IconButton>
						)}
					</Grid>
					<Collapse in={!isMobile ? true : collapseOpen}>
						<Grid item>
							<Typography
								textAlign={"left"}
								color={"grey.500"}
								sx={{
									fontFamily: "Branding SemiBold",
									fontSize: 16,
									marginTop: (theme) => theme.spacing(2),
								}}
							>
								{
									"L’INREES, qui édite Inexploré, est une entreprise qui prône le respect du vivant et de l ’être humain et qui s’engage en faveur de l’évolution des consciences. L ’écoute, l’ouverture d’esprit et la recherche perpétuelle de la qualité sont les valeurs principales que nous avons à cœur d’incarner dans notre quotidien. La bienveillance est, quant à elle, notre inspiration, tant pour nos clients, nos partenaires que pour nos collaborateurs. Figure de ces fondements déontologiques, la démarche de l ’INREES et d’Inexploré se veut :"
								}
							</Typography>
						</Grid>
						<Grid item container flexDirection={"row"} wrap={"wrap"} spacing={2} mt={2}>
							{argumentsList.map((element, index) => {
								return (
									<Grid item key={index} xs={12} sm={6} lg={4}>
										<TipArgumentCard
											icon={
												<img
													src={element.icon}
													alt={"icon"}
													style={{
														objectFit: "cover",
														width: "100%",
														height: "100%",
													}}
												/>
											}
											title={element.title}
											subTitle={element.subTitle}
										/>
									</Grid>
								);
							})}
						</Grid>
					</Collapse>
				</Grid>
			</InexCard>
		</BasicScreen>
	);
};

export default TipChoice;
