import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const DeliveryStep = ({ nb, title }) => {
	return (
		<Grid
			item
			xs={12}
			md={4}
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "35px",
					minWidth: "35px",
					height: "35px",
					minHeight: "35px",
					borderRadius: "20px",
					border: (theme) => `1px solid ${theme.palette.secondary.main}`,
					marginRight: (theme) => theme.spacing(2),
				}}
			>
				<Typography
					variant={"h4"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding Medium",
						fontSize: 16,
					}}
				>
					{nb}
				</Typography>
			</Box>
			<Typography
				variant={"h4"}
				textAlign={"left"}
				color={"secondary"}
				sx={{
					fontFamily: "Branding Medium",
					fontSize: 16,
				}}
			>
				{title}
			</Typography>
		</Grid>
	);
};

export default DeliveryStep;
