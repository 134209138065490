import React from "react";
import { Alert, Button, IconButton, Typography, styled } from "@mui/material";
import { useIsMobile } from "../hooks/responsive";
import { GradientTypo } from "./header/Header";

const Inextypo = styled(Typography)(({ theme, disabled }) => {
	return {
		fontWeight: 700,
		background: disabled === false ? undefined : theme.palette.grey["400"],
		overflow: "hidden",
		fontFamily: "Branding Bold",
		fontSize: "40px",
	};
});

const InexButton = ({
	variant = "text",
	icon = undefined,
	text = undefined,
	disabled = false,
	textWithGradient = true,
	textSx = {},
	...props
}) => {
	const Component = variant === "icon" ? IconButton : Button;
	const isMobile = useIsMobile();

	if (variant === "icon" && icon === undefined)
		return (
			<Alert severity={"error"} sx={{ color: "black" }}>
				{"Ce bouton nécessite un icône"}
			</Alert>
		);
	if (variant === "text" && text === undefined)
		return (
			<Alert severity={"error"} sx={{ color: "black" }}>
				{"Ce bouton nécessite un 'text'"}
			</Alert>
		);

	const TextComponent = textWithGradient ? GradientTypo : Inextypo;

	return (
		<Component
			{...props}
			disabled={disabled}
			sx={{
				background: (theme) => (disabled === true ? theme.palette.grey[300] : theme.palette.secondary.main),
				borderRadius: "30px",
				height: "40px",
				width: "60px",
				transition: "background 0.3s",
				"&:hover": {
					background: (theme) =>
						isMobile ? theme.palette.secondary.main : "linear-gradient(90deg, #ee7992 0%, #f1ae65 50%, #f4c54d 100%)",
					"& > p": {
						background: "white",
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
					},
				},
				...props.sx,
			}}
		>
			{variant === "text" ? (
				<TextComponent
					disabled={disabled}
					sx={{
						fontSize: 16,
						fontFamily: "Branding Medium",
						textTransform: "lowercase !important",
						"&:first-letter": {
							textTransform: "capitalize !important",
						},
						...textSx,
					}}
				>
					{text}
				</TextComponent>
			) : (
				<img alt={"buttonIcon"} src={icon} />
			)}
		</Component>
	);
};

export default InexButton;
