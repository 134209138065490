import React from "react";
import { Box, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getOrder, getSelectedBilling } from "../store/reducers/subscribeSlice";
import {
	useRedirectToAddressChoice,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice,
	useRedirectToDeliveryFormat,
	useRedirectToLanding,
	useRedirectToPayment,
	useRedirectToSubscriptionChoice,
	useRedirectToTip,
} from "../hooks/subscription";
import InexButton from "../components/InexButton";
import { useLazyCreateSepaQuery, useLazySetRenouvellementQuery } from "../store/api/subscribeTunnel";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import InexCard from "../components/InexCard";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import { useGetSepaQuery } from "../store/api/user";
import { useNavigate } from "react-router";
import { routesDefinitions } from "../routes/RoutesDefinitions";
import GoogleTagManager from "../components/seo/GoogleTagManager";

const RenewSubscriptionScreen = ({ title }) => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAddressChoice();
	useRedirectToTip();
	useRedirectToPayment();

	useRedirectToLogin();
	useLogUserIn();

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const authToken = useSelector(getAuthToken);
	const inreesId = useSelector(getInreesId);

	const order = useSelector(getOrder);

	const selectedBilling = useSelector(getSelectedBilling);

	const [createSepa] = useLazyCreateSepaQuery();

	const [value, setValue] = React.useState(2);

	const { data: sepaData } = useGetSepaQuery({
		authToken,
		idinexplore: inreesId,
	});

	const [setTypePaiement] = useLazySetRenouvellementQuery();

	const navigate = useNavigate();

	const handleValidation = () => {
		switch (value) {
			case "2":
				setTypePaiement({
					authToken,
					idinexplore: inreesId,
					order,
					typepaiement: parseInt(value),
				}).then(({ data }) => {
					navigate(routesDefinitions["PAYMENT_VALIDATION"].routerObj.path);
				});
				break;
			case "3": {
				const hasSepa = sepaData?.sepas?.length > 0;
				if (hasSepa === true) {
					setTypePaiement({
						authToken,
						idinexplore: inreesId,
						order,
						typepaiement: parseInt(value),
					}).then(({ data }) => {
						navigate(routesDefinitions["PAYMENT_VALIDATION"].routerObj.path);
					});
				} else {
					const now = new Date();
					const ts = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()} ${now.getHours()}:${now.getMinutes()}:00`;
					createSepa({
						idcommande: order.idcommande,
						idinexplore: inreesId,
						authToken,
						ts,
					}).then(({ data }) => {
						if (data) window.location.href = data.redirectFlow.redirect_url;
					});
				}
				break;
			}
			default:
			case "0":
				setTypePaiement({
					authToken,
					idinexplore: inreesId,
					order,
					typepaiement: parseInt(value),
				}).then(({ data }) => {
					navigate(routesDefinitions["PAYMENT_VALIDATION"].routerObj.path);
				});
				break;
		}
	};

	return (
		<RadioGroup
			value={value}
			defaultValue={2}
			onChange={(e) => {
				setValue(e.target.value);
			}}
		>
			<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
				<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
					<InexCard
						sx={{
							paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
							paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						}}
					>
						<Grid container>
							<Grid item xs={12}>
								<Typography
									variant={"h4"}
									textAlign={"left"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding Bold",
										fontSize: 20,
										marginBottom: (theme) => theme.spacing(5),
									}}
								>
									{title}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant={"h4"}
									textAlign={"left"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding Bold",
										fontSize: 16,
										marginBottom: (theme) => theme.spacing(2),
									}}
								>
									{(!selectedBilling || selectedBilling?.titre !== "Mensuel") ?
										"Pour simplifier le renouvellement de votre abonnement, nous vous proposons de l'automatiser chaque année. Si vous souhaitez un renouvellement automatique annuel, choisissez le mode de paiement associé :"
										:
										"Pour simplifier votre renouvellement automatique mensuel nous vous proposons une solution de paiement facile par prélèvement SEPA. Souhaitez vous en bénéficier ?"
									}

								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										flexWrap: "nowrap",
									}}
								>
									<Radio value={2} color={"secondary"} />
									<Typography
										variant={"h4"}
										textAlign={"left"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 16,
											marginLeft: (theme) => theme.spacing(2),
										}}
									>
										{(!selectedBilling || selectedBilling?.titre !== "Mensuel") ?
											"Je choisis un renouvellement avec la CB que je viens d'utiliser"
										:
											"Je garde le renouvellement avec la CB que je viens d'utiliser"
										}

									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										flexWrap: "nowrap",
									}}
								>
									<Radio value={3} color={"secondary"} />
									<Box
										display={"flex"}
										flexDirection={"column"}
										sx={{
											marginLeft: (theme) => theme.spacing(2),
										}}
									>
										<Typography
											variant={"h4"}
											textAlign={"left"}
											color={"secondary"}
											sx={{
												fontFamily: "Branding SemiBold",
												fontSize: 16,
											}}
										>
											{
												"Je choisis le renouvellement par prélèvement SEPA*"
											}
										</Typography>
										<Typography fontFamily={"Branding Medium"} fontSize={14} color={"secondary"}>
											{
												"*Solution de paiement entièrement sécurisée mise en place avec notre partenaire GoCardless."
											}
										</Typography>
									</Box>
								</Box>
							</Grid>
							{(!selectedBilling || selectedBilling?.titre !== "Mensuel") && (
								<Grid item xs={12}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											flexWrap: "nowrap",
										}}
									>
										<Radio value={0} color={"secondary"} />
										<Typography
											variant={"h4"}
											textAlign={"left"}
											color={"secondary"}
											sx={{
												fontFamily: "Branding SemiBold",
												fontSize: 16,
												marginLeft: (theme) => theme.spacing(2),
											}}
										>
											{"J'ai besoin de réfléchir, je pourrai activer moi-même le renouvellement plus tard dans mon compte."}
										</Typography>
									</Box>
								</Grid>
							)}

							<Grid iten xs={0} sm={0} md={1} lg={3} />
							<Grid item xs={12} sm={12} md={10} lg={6}>
								<InexButton
									text={"Valider"}
									sx={{ width: "100%", margin: "auto", height: "50px", marginTop: (theme) => theme.spacing(2) }}
									disabled={value === null}
									onClick={() => {
										handleValidation();
									}}
								/>
							</Grid>
							<Grid iten xs={0} sm={0} md={1} lg={3} />
						</Grid>
					</InexCard>
				</Grid>
			</Grid>
			<GoogleTagManager />
		</RadioGroup>
	);
};

export default RenewSubscriptionScreen;
