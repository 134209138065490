import React from "react";
import { Grid, Typography } from "@mui/material";
import InexCard from "../components/InexCard";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import InexButton from "../components/InexButton";
import { useNavigate } from "react-router";
import { routesDefinitions } from "./RoutesDefinitions";
import {
	useRedirectToAddressChoice,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice,
	useRedirectToDeliveryFormat,
	useRedirectToLanding,
	useRedirectToPayment,
	useRedirectToSubscriptionChoice,
	useRedirectToTip,
} from "../hooks/subscription";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import checkIcon from "../resources/images/svg/CheckIcon.svg";

const PaymentError = () => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAddressChoice();
	useRedirectToTip();
	useRedirectToPayment();

	useRedirectToLogin();
	useLogUserIn();

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const navigate = useNavigate();

	const scrollToContainer = () => {
		console.log("Srolling")
	}


	return (
		<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
			<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
				<InexCard
					sx={{
						paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								variant={"h2"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 30,
									marginBottom: (theme) => theme.spacing(1),
								}}
							>
								{"Oups !"}
							</Typography>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 24,
									marginBottom: (theme) => theme.spacing(1),
								}}
							>
								{"Il semblerait qu'il y ait eu une erreur lors du paiement de votre commande 😢"}
							</Typography>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
						<Grid item xs={12} sm={12} md={10} lg={6}>
							<InexButton
								text={"Retourner à la page de paiement"}
								onClick={() => {
									navigate(routesDefinitions.PAYMENT.routerObj.path);
								}}
								sx={{
									marginTop: (theme) => theme.spacing(5),
									width: "100%",
									height: "50px",
								}}
							/>
							<InexButton
								text={"Contacter le SAV"}
								variant={"text"}
								textWithGradient={false}
								onClick={scrollToContainer}
								sx={{
									marginTop: (theme) => theme.spacing(4),
									width: "100%",
									height: "50px",
									background: (theme) => ( "transparent" ),
									border: (theme) => ( `1px solid ${theme.palette.secondary.main}` ),
									color: (theme) => theme.palette.secondary.main,
									"&:hover": {
										backgroundColor: "grey.300",
									},
								}}
							/>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
					</Grid>
				</InexCard>
			</Grid>
		</Grid>
	);
};

export default PaymentError;
