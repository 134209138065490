import React from "react";
import { Box, Card, IconButton, styled, Typography } from "@mui/material";
import ArrowIcon from "../../resources/images/svg/ButtonArrow.svg";
import { useIsLarge, useIsMobile, useIsTablet } from "../../hooks/responsive";
import InexButton from "../InexButton";

const ChoiceCard = ({ image, imageMobile, title, ...props }) => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const isLarge = useIsLarge();

	return (
		<Card
			sx={{
				width: "100%",
				display: "flex",
				height: "100%",
				borderRadius: 0,
				boxShadow: (theme) => theme.shadows[25],
				"&:hover": {
					transform: isMobile ? undefined : "scale(1.05, 1.05)",
					transition: isMobile ? undefined : "transform 0.5s",
					cursor: isMobile ? undefined : "pointer",
				},
			}}
			{...props}
		>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						height: isMobile ? "30vw" : undefined,
						maxHeight: "50vh",
						marginBottom: (theme) => (isMobile ? 0 : theme.spacing(2)),
						overflow: "hidden",
						display: "flex",
						justifyContent: "flex-start",
					}}
				>
					<img
						src={isMobile ? imageMobile : image}
						alt={"Je m'abonne"}
						style={{
							objectFit: isMobile ? "contain" : "cover",
							objectPosition: "left bottom",
							width: "100%",
							height: "100%",
							maxHeight: isTablet ? "30vh" : "50vh",
						}}
					/>
				</Box>
				<Box p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
					<Typography
						sx={{
							color: (theme) => theme.palette.secondary.main,
							fontFamily: "Branding Bold",
							fontSize: isLarge ? 18 : undefined,
							marginRight: (theme) => theme.spacing(1),
						}}
						textAlign={"left"}
					>
						{title}
					</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							height: "100%",
						}}
					>
						<InexButton variant={"icon"} icon={ArrowIcon} />
					</Box>
				</Box>
			</Box>
		</Card>
	);
};

export default ChoiceCard;
