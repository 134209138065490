import { api } from "./rtkApi";
import { encode } from "querystring";

export const searchApi = api.injectEndpoints({
	endpoints: (build) => ({
		getPays: build.query({
			query: () => {
				return {
					method: "GET",
					url: "/common/pays",
				};
			},
		}),
		getTypeDAbonnements: build.query({
			query: ({ tunnel, zone, ts }) => {
				return {
					method: "GET",
					url: `/abo/list/${tunnel}/${zone}?ts=${ts}`,
				};
			},
		}),
		getCycleAbonnement: build.query({
			query: ({ selectedSubscriptions, tunnel, zone }) => {
				const hasDigital = selectedSubscriptions.findIndex((e) => e.idabo === 1) !== -1;
				const hasTv = selectedSubscriptions.findIndex((e) => e.idabo === 2) !== -1;
				const hasMag = selectedSubscriptions.findIndex((e) => e.idabo === 3) !== -1;

				return {
					method: "POST",
					url: `/abo/freq`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						tunnel,
						zone,
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
					}),
				};
			},
		}),
		getAddressCost: build.query({
			query: ({ authToken, zone, idinexplore, selectedSubscriptions, frequence }) => {
				const hasDigital = selectedSubscriptions.findIndex((e) => e.idabo === 1) !== -1;
				const hasTv = selectedSubscriptions.findIndex((e) => e.idabo === 2) !== -1;
				const hasMag = selectedSubscriptions.findIndex((e) => e.idabo === 3) !== -1;

				return {
					method: "POST",
					url: `/abo/cout-adresse`,
					headers: {
						Authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore,
						zone,
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
						frequence,
					}),
				};
			},
		}),
		createAbonnement: build.query({
			query: ({
				authToken,
				zone,
				idinexplore,
				selectedSubscriptions,
				selectedBilling,
				selectedAddress,
				total,
				applyTip,
				tipAmount,
				tipPeriod,
				typepaiement = 2,
				promo,
				ts,
				...rest
			}) => {
				const hasDigital = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 1) !== -1;
				const hasTv = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 2) !== -1;
				const hasMag = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 3) !== -1;

				return {
					method: "POST",
					url: `/abo/cmd?ts=${ts}`,
					headers: {
						Authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore,
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
						zone,
						frequence: selectedBilling.frequence,
						don: applyTip === true ? tipAmount : 0,
						typedon: tipPeriod,
						codepromo: promo?.code,
						total: total,
						idadresse: selectedAddress.idadresse,
						typepaiement: typepaiement,
					}),
				};
			},
		}),
		createCarteCadeau: build.query({
			query: ({
				authToken,
				idinexplore,
				zone,
				selectedSubscriptions,
				selectedBilling,
				selectedAddress,
				total,
				applyTip,
				tipAmount,
				tipPeriod,
				typepaiement,
				ts,
				giftMethod,
				giftFirstName,
				giftLastName,
				giftEmail,
				giftPhone,
				promo,
				...rest
			}) => {
				const hasDigital = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 1) !== -1;
				const hasTv = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 2) !== -1;
				const hasMag = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 3) !== -1;

				return {
					method: "POST",
					url: `/abo/cado?ts=${ts}`,
					headers: {
						Authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore,
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
						zone,
						frequence: selectedBilling.frequence,
						don: applyTip === true ? tipAmount : 0,
						typedon: tipPeriod,
						codepromo: promo?.code,
						total: total,
						idadresse: selectedAddress.idadresse,
						typepaiement: typepaiement,
						prenomFilleul: giftMethod === 1 ? giftFirstName : null,
						nomFilleul: giftMethod === 1 ? giftLastName : null,
						emailFilleul: giftMethod === 1 ? giftEmail : null,
						telFilleul: giftMethod === 1 ? giftPhone : null,
					}),
				};
			},
		}),
		getCarteCadeau: build.query({
			query: ({ code, ts }) => {
				return {
					method: "POST",
					url: `/abo/codecadeau?ts=${ts}`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						code,
					}),
				};
			},
		}),
		activerCarteCadeau: build.query({
			query: ({
				authToken,
				zone,
				idinexplore,
				giftCardData,
				selectedAddress,
				total,
				applyTip,
				tipAmount,
				tipPeriod,
				typepaiement = 2,
				promo,
				ts,
				...rest
			}) => {
				const hasDigital = giftCardData && giftCardData.infocode.digital;
				const hasTv = giftCardData && giftCardData.infocode.tv;
				const hasMag = giftCardData && giftCardData.infocode.mag;

				return {
					method: "POST",
					url: `/abo/active-cado?ts=${ts}`,
					headers: {
						Authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore,
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
						zone,
						frequence: giftCardData.infocode.frequence,
						don: applyTip === true ? tipAmount : 0,
						typedon: tipPeriod,
						codepromo: promo?.code,
						total: total,
						idadresse: selectedAddress.idadresse,
						typepaiement: typepaiement,
						code: giftCardData.infocode.code,
					}),
				};
			},
		}),
		createSepa: build.query({
			query: ({ authToken, idinexplore, idcommande, ts, ...rest }) => {
				return {
					method: "POST",
					url: `/abo/creer-sepa?ts=${ts}`,
					headers: {
						Authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore,
						idcommande,
					}),
				};
			},
		}),
		setRenouvellement: build.query({
			query: ({ authToken, idinexplore, order, typepaiement }) => {
				const hasDigital = order && order.details.findIndex((e) => e.idabo === 1) !== -1;
				const hasTv = order && order.details.findIndex((e) => e.idabo === 2) !== -1;
				const hasMag = order && order.details.findIndex((e) => e.idabo === 3) !== -1;

				return {
					method: "POST",
					url: `/abo/set-typepaiement`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
						typepaiement,
					}),
				};
			},
		}),
		verifPromo: build.query({
			query: ({ 
				authToken, 
				idinexplore, 
				codepromo,
				selectedSubscriptions,
				selectedBilling,
				selectedAddress 
			}) => {
				const hasDigital = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 1) !== -1;
				const hasTv = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 2) !== -1;
				const hasMag = selectedSubscriptions && selectedSubscriptions.findIndex((e) => e.idabo === 3) !== -1;

				return {
					method: "POST",
					url: `/common/verif-promo`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						codepromo,
						digital: hasDigital ? 1 : 0,
						tv: hasTv ? 1 : 0,
						mag: hasMag ? 1 : 0,
						zone: selectedAddress.zone,
						frequence: selectedBilling.frequence,
					}),
				};
			},
		}),
		getOrder: build.query({
			query: ({ idcommande }) => {
				return {
					method: "POST",
					url: `/abo/get-order`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idcommande
					}),
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetPaysQuery,
	useLazyGetPaysQuery,
	useGetTypeDAbonnementsQuery,
	useLazyGetTypeDAbonnementsQuery,
	useGetCycleAbonnementQuery,
	useLazyGetCycleAbonnementQuery,
	useGetAddressCostQuery,
	useLazyGetAddressCostQuery,
	useCreateAbonnementQuery,
	useLazyCreateAbonnementQuery,
	useCreateCarteCadeauQuery,
	useLazyCreateCarteCadeauQuery,
	useGetCarteCadeauQuery,
	useLazyGetCarteCadeauQuery,
	useActiverCarteCadeauQuery,
	useLazyActiverCarteCadeauQuery,
	useCreateSepaQuery,
	useLazyCreateSepaQuery,
	useSetRenouvellementQuery,
	useLazySetRenouvellementQuery,
	useVerifPromoQuery,
	useLazyVerifPromoQuery,

	useGetOrderQuery,
	useLazyGetOrderQuery
} = searchApi;
