import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getInreesId,
	getInreesToken,
	setAuthToken,
	setInreesId,
	setInreesToken,
	setShowSnackbar,
} from "../store/reducers/authSlice";
import { useLazyGetAuthKeyQuery } from "../store/api/user";
import { useCookie, useCookieWatcher } from "@fcannizzaro/react-use-cookie-watcher";

export const useIsLoggedIn = () => {
	const [isLoggedIn, setIsLoggedIn] = React.useState(null);

	const inreesId = useSelector(getInreesId);
	const inreesToken = useSelector(getInreesToken);

	const inexIDExists = useCookieWatcher("INEXPLORE_ID", {
		checkEvery: 5000,
		valueOnly: true,
	});
	const inexID = useCookie("INEXPLORE_ID");
	const inexTokenExists = useCookieWatcher("INEXPLORE_TOKEN", {
		checkEvery: 5000,
		valueOnly: true,
	});
	const inexToken = useCookie("INEXPLORE_TOKEN");

	const dispatch = useDispatch();
	React.useEffect(() => {
		if (inexIDExists && inexTokenExists) {
			if (inexID !== inreesId || inexToken !== inreesToken) {
				dispatch(setInreesId(inexID));
				dispatch(setInreesToken(inexToken));
				dispatch(setShowSnackbar(true));
			}
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
			dispatch(setInreesId(null));
			dispatch(setInreesToken(null));
			dispatch(setShowSnackbar(false));
		}
	}, [inreesId, inreesToken, inexID, inexToken, inexIDExists, inexTokenExists]);

	return isLoggedIn;
};

export const useLogUserIn = () => {
	const inreesId = useSelector(getInreesId);
	const inreesToken = useSelector(getInreesToken);

	const [fetchAuthKey, { isFetching }] = useLazyGetAuthKeyQuery();

	const dispatch = useDispatch();
	React.useEffect(() => {
		if (inreesId && inreesToken) {
			const now = new Date();
			const ts = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()} ${now.getHours()}:${now.getMinutes()}:00`;
			fetchAuthKey({ inreesId, inreesToken, ts }).then(({ data }) => {
				dispatch(setAuthToken(data.cle));
			});
		} else {
			dispatch(setAuthToken(null));
		}
	}, [inreesId, inreesToken]);
};

export const getLoginRedirectURL = () => {
	return `${
		process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://login.inexplore.com/"
	}/?referrer=${window.location.href}`;
};

export const useRedirectToLogin = () => {
	const isLoggedIn = useIsLoggedIn();

	React.useEffect(() => {
		const redirectUrl = getLoginRedirectURL();
		if (isLoggedIn === false) window.location.replace(redirectUrl);
	}, [isLoggedIn]);
};
