import React from "react";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import InexCard from "../components/InexCard";
import { useSelector } from "react-redux";
import { getOrder } from "../store/reducers/subscribeSlice";
import PriceFormater from "../components/PriceFormater";
import InexButton from "../components/InexButton";
import {
	useRedirectToAddressChoice,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice, useRedirectToDeliveryFormat,
	useRedirectToLanding, useRedirectToPayment,
	useRedirectToSubscriptionChoice, useRedirectToTip
} from "../hooks/subscription";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";

const CheckSending = () => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAddressChoice();
	useRedirectToTip();
	useRedirectToPayment();

	useRedirectToLogin();
	useLogUserIn();

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const order = useSelector(getOrder);

	return (
		<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
			<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
				<InexCard
					sx={{
						paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 30,
									marginBottom: (theme) => theme.spacing(5),
								}}
							>
								{"Félicitations ! Votre commande a été prise en compte."}
							</Typography>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 20,
									marginBottom: (theme) => theme.spacing(3),
								}}
							>
								{"Informations de la commande :"}
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "row",
								}}
							>
								<Typography
									variant={"h4"}
									textAlign={"center"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding SemiBold",
										fontSize: 20,
										textTransform: "uppercase",
										marginRight: (theme) => theme.spacing(1),
									}}
								>
									{"MONTANT : "}
								</Typography>
								<PriceFormater
									price={order.total}
									withTaxes={true}
									withTimeFrame={false}
									withTitle={false}
									withSign={false}
									priceProps={{
										sx: {
											fontSize: 20,
										},
									}}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "row",
									marginBottom: (theme) => theme.spacing(5),
								}}
							>
								<Typography
									variant={"h4"}
									textAlign={"center"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding SemiBold",
										fontSize: 20,
										textTransform: "uppercase",
										marginRight: (theme) => theme.spacing(1),
									}}
								>
									{"N˚ DE COMMANDE : "}
								</Typography>
								<Typography
									variant={"h4"}
									textAlign={"center"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding Bold",
										fontSize: 20,
										textTransform: "uppercase",
									}}
								>
									{order.reference}
								</Typography>
							</Box>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: 20,
									marginBottom: (theme) => theme.spacing(2),
								}}
							>
								{
									"Vous avez choisi un mode de paiement par chèque. Afin que nous puissions traiter votre commande, merci :"
								}
							</Typography>
							<ul>
								<li>
									<Typography
										variant={"h4"}
										textAlign={"center"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 20,
											marginBottom: (theme) => theme.spacing(1),
											marginLeft: (theme) => theme.spacing(isMobile ? 2 : 5),
										}}
									>
										{"• D'inscrire au dos du chèque le numéro de votre commande ci-dessus."}
									</Typography>
								</li>
								<li>
									<Typography
										variant={"h4"}
										textAlign={"center"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 20,
											marginBottom: (theme) => theme.spacing(isMobile ? 1 : 3),
											marginLeft: (theme) => theme.spacing(isMobile ? 2 : 5),
										}}
									>
										{"• De nous adresser votre chèque à l'ordre de l'INREES à l'adresse suivante :"}
									</Typography>
									<Typography
										variant={"h4"}
										textAlign={"center"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding Bold",
											fontSize: 20,
											marginLeft: (theme) => theme.spacing(2),
										}}
									>
										{"INREES"}
									</Typography>
									<Typography
										variant={"h4"}
										textAlign={"center"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 20,
											marginLeft: (theme) => theme.spacing(2),
										}}
									>
										{"67 rue Saint-Jacques"}
									</Typography>
									<Typography
										variant={"h4"}
										textAlign={"center"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 20,
											marginLeft: (theme) => theme.spacing(2),
										}}
									>
										{"75005, Paris France"}
									</Typography>
								</li>
							</ul>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding SemiBold",
									fontSize: 20,
									marginBottom: (theme) => theme.spacing(1),
									marginTop: (theme) => theme.spacing(3),
								}}
							>
								{"Dès la réception de votre paiement, nous traiterons votre commande."}
							</Typography>
							<Typography
								variant={"h4"}
								textAlign={"center"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding SemiBold",
									fontSize: 20,
									marginBottom: (theme) => theme.spacing(isMobile ? 0 : 3),
								}}
							>
								{"Inexploré vous remercie de votre confiance."}
							</Typography>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
						<Grid item xs={12} sm={12} md={10} lg={6}>
							<InexButton
								text={"Aller sur mon profil"}
								onClick={() => {
									window.location.href = "https://my.inexplore.com/profil";
								}}
								sx={{
									marginTop: (theme) => theme.spacing(isMobile ? 3 : 5),
									width: "100%",
									height: "50px",
								}}
							/>
						</Grid>
						<Grid iten xs={0} sm={0} md={1} lg={3} />
					</Grid>
				</InexCard>
			</Grid>
		</Grid>
	);
};

export default CheckSending;
