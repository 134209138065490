import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
	getApplyTip,
	getGiftMethod,
	getOrder,
	getSelectedAddress,
	getSelectedBilling,
	getSelectedCountry,
	getSelectedSubscriptions,
	getTipAmount,
	getTipPeriod,
	getTunnel,
	removeSubscription,
	setTunnel,
} from "../store/reducers/subscribeSlice";
import { routesDefinitions } from "../routes/RoutesDefinitions";
import { useGetTypeDAbonnementsQuery } from "../store/api/subscribeTunnel";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import { useGetAbosQuery } from "../store/api/user";

export const useRedirectToLanding = () => {
	// Navigation
	const navigate = useNavigate();

	const tunnel = useSelector(getTunnel);

	React.useEffect(() => {
		if (tunnel === null) {
			console.debug("Redirect to LANDING");
			navigate(routesDefinitions["LANDING"].routerObj.path);
		}
	}, [tunnel]);
};

export const useRedirectToCountryChoice = () => {
	// Navigation
	const navigate = useNavigate();

	const tunnel = useSelector(getTunnel);
	const selectedCountry = useSelector(getSelectedCountry);

	React.useEffect(() => {
		if (tunnel !== 2 && selectedCountry === null) {
			console.debug("Redirect to COUNTRY_CHOICE");
			navigate(routesDefinitions["COUNTRY_CHOICE"].routerObj.path);
		}
	}, [selectedCountry, tunnel]);
};

export const useRedirectToSubscriptionChoice = () => {
	// Navigation
	const navigate = useNavigate();

	const tunnel = useSelector(getTunnel);
	const selectedSubscriptions = useSelector(getSelectedSubscriptions);

	React.useEffect(() => {
		if (tunnel !== 2 && (selectedSubscriptions === null || selectedSubscriptions.length === 0)) {
			console.debug("Redirect to SUBSCRIPTION_CHOICE");
			navigate(routesDefinitions["SUBSCRIPTION_CHOICE"].routerObj.path);
		}
	}, [selectedSubscriptions, tunnel]);
};

export const useRedirectToBillingChoice = () => {
	// Navigation
	const navigate = useNavigate();

	const tunnel = useSelector(getTunnel);
	const selectedBilling = useSelector(getSelectedBilling);

	React.useEffect(() => {
		if (tunnel !== 2 && selectedBilling == null) {
			console.debug("Redirect to BILLING_CHOICE");
			navigate(routesDefinitions["BILLING_CHOICE"].routerObj.path);
		}
	}, [selectedBilling, tunnel]);
};

export const useRedirectToDeliveryFormat = () => {
	// Navigation
	const navigate = useNavigate();

	const tunnel = useSelector(getTunnel);
	const giftMethod = useSelector(getGiftMethod);

	React.useEffect(() => {
		if (tunnel === 1 && giftMethod == null) {
			console.debug("Redirect to DELIVERY_FORMAT");
			navigate(routesDefinitions["DELIVERY_FORMAT"].routerObj.path);
		}
	}, [tunnel, giftMethod]);
};

export const useRedirectToAddressChoice = () => {
	// Navigation
	const navigate = useNavigate();

	const address = useSelector(getSelectedAddress);

	React.useEffect(() => {
		if (address == null) {
			console.debug("Redirect to ADDRESS_CHOICE");
			navigate(routesDefinitions["ADDRESS_CHOICE"].routerObj.path);
		}
	}, [address]);
};

export const useRedirectToTip = () => {
	// Navigation
	const navigate = useNavigate();

	const applyTip = useSelector(getApplyTip);
	const tipAmount = useSelector(getTipAmount);
	const tipPeriod = useSelector(getTipPeriod);

	React.useEffect(() => {
		if (applyTip === true && (tipAmount == null || tipPeriod == null)) {
			console.debug("Redirect to TIP_CHOICE");
			navigate(routesDefinitions["TIP_CHOICE"].routerObj.path);
		}
	}, [applyTip, tipAmount, tipPeriod]);
};

export const useRedirectToPayment = () => {
	// Navigation
	const navigate = useNavigate();

	const order = useSelector(getOrder);

	React.useEffect(() => {
		if (!order) {
			console.debug("Redirect to PAYMENT");
			navigate(routesDefinitions["PAYMENT"].routerObj.path);
		}
	}, [order]);
};

export const useRedirectToAlreadySubscribed = () => {
	// Navigation
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const tunnel = useSelector(getTunnel);
	const selectedCountry = useSelector(getSelectedCountry);
	const [subscriptionTypes, setSubscriptionTypes] = React.useState(null);

	const now = new Date();
	const ts = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()} ${now.getHours()}:00:00`;
	const { data, isFetching } = useGetTypeDAbonnementsQuery(
		{
			tunnel,
			zone: selectedCountry?.zone,
			ts,
		},
		{
			skip: selectedCountry == null || tunnel === null,
		}
	);

	React.useEffect(() => {
		if (data) {
			setSubscriptionTypes(data.abos);
		}
	}, [data]);

	const authToken = useSelector(getAuthToken);
	const inreesId = useSelector(getInreesId);

	const { data: dataAbos } = useGetAbosQuery(
		{
			authToken,
			idinexplore: inreesId,
		},
		{
			skip: !authToken || !inreesId,
		}
	);

	const selectedSubscriptions = useSelector(getSelectedSubscriptions);

	React.useEffect(() => {
		if (tunnel === 1) return;
		const availableSubs = subscriptionTypes?.filter((element) => {
			if (element.idabo === 1 && dataAbos?.abos?.digital == 1) return false;
			if (element.idabo === 3 && dataAbos?.abos?.mag == 1) return false;
			if (element.idabo === 2 && dataAbos?.abos?.tv == 1) return false;
			return true;
		});

		let subsChanged = false;
		selectedSubscriptions.forEach((sub) => {
			// if we cannot find the current sub the subs that are available to the user, we need to remove it from the selected list.
			const isAvailable = availableSubs?.findIndex((e) => e.idabo === sub.idabo) !== -1;
			if (!isAvailable) {
				dispatch(removeSubscription(sub));
				subsChanged = true;
			}
		});

		if (availableSubs?.length === 0) {
			navigate(routesDefinitions["ALREADY_SUBSCRIBED"].routerObj.path);
		} else if (subsChanged === true) {
			navigate(routesDefinitions["SUBSCRIPTION_CHOICE"].routerObj.path);
		}
	}, [subscriptionTypes, dataAbos, selectedSubscriptions]);
};
