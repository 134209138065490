import { api } from "./api/rtkApi";
import { combineReducers } from "@reduxjs/toolkit";
import SubscribeSlice from "./reducers/subscribeSlice";
import AuthSlice from "./reducers/authSlice";
import GiftCardSlice from "./reducers/giftCardSlice";

export default combineReducers({
	[api.reducerPath]: api.reducer,
	subscribe: SubscribeSlice,
	auth: AuthSlice,
	giftCard: GiftCardSlice
});
