import React from "react";
import { Box, Typography } from "@mui/material";

const PriceFormater = ({
	price,
	period,
	withTitle = true,
	withTimeFrame = true,
	priceProps = {},
	timeFrameProps = {},
	withSign = false,
	withTaxes = false,
	...props
}) => {
	const frequencyName = React.useMemo(() => {
		if (period === "Mensuel") return "mois";
		if (period === "Annuel") return "an";
		if (period === "Semestriel") return "6 mois";
		if (period === "Trimestriel") return "3 mois";
		return "mois";
	}, [period]);

	return (
		<Box
			{...props}
			sx={{
				display: "flex",
				alignItems: "flex-start",
				flexDirection: "column",
				...props.sx,
			}}
		>
			{withTitle !== false && (
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 15,
						maxWidth: "95%"
					}}
				>
					{typeof withTitle !== "boolean" ? withTitle : "À partir de :"}
				</Typography>
			)}
			<Box
				sx={{
					display: "flex",
					flexWrap: "nowrap",
					justifyContent: "center",
					alignItems: "flex-end",
					width: "100%"
				}}
			>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"secondary"}
					{...priceProps}
					sx={{
						fontFamily: "Branding Bold",
						fontSize: 24,
						textAlign: "center",
						...priceProps.sx,
					}}
				>
					{withSign && price > 0 && "+"}
					{(parseInt(price) / 100).toFixed(2).replace(".", ",") + "€"}
					{withTaxes === true ? " TTC" : ""}
				</Typography>
				{withTimeFrame === true && (
					<Typography
						component={"div"}
						textAlign={"left"}
						color={"secondary"}
						{...timeFrameProps}
						sx={{
							fontFamily: "Branding Medium",
							fontSize: 16,
							paddingBottom: 0.5,
							paddingLeft: 0.5,
							...timeFrameProps.sx,
						}}
					>
						{`/${frequencyName}`}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default PriceFormater;
