import React from "react";
import { calculateTotalAmount } from "../components/SubscriptionDetail";
import { Box, Grid, Radio, Typography } from "@mui/material";
import {
	useRedirectToAddressChoice,
	useRedirectToAlreadySubscribed,
	useRedirectToBillingChoice,
	useRedirectToCountryChoice,
	useRedirectToDeliveryFormat,
	useRedirectToLanding,
	useRedirectToSubscriptionChoice,
	useRedirectToTip,
} from "../hooks/subscription";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import BasicScreen from "../components/BasicScreen";
import { routesDefinitions } from "./RoutesDefinitions";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import {
	getApplyTip,
	getOrder,
	getPaymentMethod,
	getPromo,
	getSelectedAddress,
	getSelectedAddressCost,
	getSelectedBilling,
	getSelectedCountry,
	getSelectedSubscriptions,
	getTipAmount,
	getTipPeriod,
	getTunnel,
	setOrder,
	setPaymentMethod,
} from "../store/reducers/subscribeSlice";
import InexCard from "../components/InexCard";
import { TopBorder } from "../components/billingChoice/BillingCard";

import lockIcon from "../resources/images/svg/lock@1,5x.svg";
import CBIcon from "../resources/images/svg/payment/CB.svg";
import MCIcon from "../resources/images/svg/payment/MASTER_CARD.svg";
import VISA from "../resources/images/svg/payment/VISA.svg";
import AMEXIcon from "../resources/images/svg/payment/AMEX.svg";
import {
	useLazyActiverCarteCadeauQuery,
	useLazyCreateAbonnementQuery,
	useLazyCreateCarteCadeauQuery,
} from "../store/api/subscribeTunnel";
import { useStep } from "../hooks/useStep";
import {
	getGiftEmail,
	getGiftFirstName,
	getGiftLastName,
	getGiftMethod,
	getGiftPhone,
} from "../store/reducers/subscribeSlice";
import { getGiftCardData } from "../store/reducers/giftCardSlice";
import { useNavigate } from "react-router";

const Payment = () => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToBillingChoice();
	useRedirectToDeliveryFormat();
	useRedirectToAddressChoice();
	useRedirectToTip();
	useRedirectToAlreadySubscribed();

	useRedirectToLogin();
	useLogUserIn();

	const tunnel = useSelector(getTunnel);

	const authToken = useSelector(getAuthToken);
	const inreesId = useSelector(getInreesId);

	const selectedCountry = useSelector(getSelectedCountry);
	const selectedSubscriptions = useSelector(getSelectedSubscriptions);
	const selectedBilling = useSelector(getSelectedBilling);
	const selectedAddress = useSelector(getSelectedAddress);
	const selectedAddressCost = useSelector(getSelectedAddressCost);
	const applyTip = useSelector(getApplyTip);
	const tipAmount = useSelector(getTipAmount);
	const tipPeriod = useSelector(getTipPeriod);

	// case of creation of a giftCard.
	const giftMethod = useSelector(getGiftMethod);
	const giftFirstName = useSelector(getGiftFirstName);
	const giftLastName = useSelector(getGiftLastName);
	const giftEmail = useSelector(getGiftEmail);
	const giftPhone = useSelector(getGiftPhone);

	const giftCardData = useSelector(getGiftCardData);

	const promo = useSelector(getPromo);

	const total = React.useMemo(() => {
		return calculateTotalAmount(selectedBilling?.montant, selectedAddressCost, applyTip, tipAmount, promo);
	}, [selectedBilling, selectedAddressCost, applyTip, tipAmount, promo]);

	const stepNb = useStep("PAYMENT");

	// TODO : Show a button to directly create the order.

	const variables = React.useMemo(() => {
		if (!selectedAddress) return;
		return {
			authToken,
			zone: selectedAddress.zone,
			idinexplore: inreesId,
			selectedSubscriptions,
			selectedBilling,
			selectedAddress,
			total,
			applyTip,
			tipAmount: tipAmount * 100,
			tipPeriod,
			giftMethod,
			giftFirstName,
			giftLastName,
			giftEmail,
			giftPhone,
			giftCardData,
			promo,
		};
	}, [
		authToken,
		selectedCountry,
		inreesId,
		selectedSubscriptions,
		selectedBilling,
		selectedAddress,
		total,
		applyTip,
		tipAmount,
		tipPeriod,

		giftMethod,
		giftFirstName,
		giftLastName,
		giftEmail,
		giftPhone,

		giftCardData,
		promo,
	]);

	const [createSubscription] = useLazyCreateAbonnementQuery();
	const [createGiftCard] = useLazyCreateCarteCadeauQuery();
	const [activateGiftCard] = useLazyActiverCarteCadeauQuery();

	const paymentMethod = useSelector(getPaymentMethod);

	const dispatch = useDispatch();
	const setPaymentMethodInStore = (pm) => {
		dispatch(setPaymentMethod(pm));
	};

	React.useEffect(() => {
		if (!(tunnel !== 2 && selectedBilling.titre !== "Mensuel")) setPaymentMethodInStore(2);
	}, [tunnel, selectedBilling]);

	const isCB = React.useMemo(() => {
		return paymentMethod === 2;
	}, [paymentMethod]);
	const isCheck = React.useMemo(() => {
		return paymentMethod === 1;
	}, [paymentMethod]);

	let skipCreation = !inreesId || !selectedAddress || tipPeriod === -1;

	if (skipCreation === false) {
		if (tunnel !== 2)
			skipCreation = !variables || !selectedCountry || !selectedSubscriptions || !selectedBilling || !paymentMethod;
		else skipCreation = !giftCardData;
	}

	const order = useSelector(getOrder);
	const setOrderInStore = (val) => {
		dispatch(setOrder(val));
	};

	const [isFormReady, setIsFormReady] = React.useState(false);
	const formRef = React.useRef(null);

	React.useEffect(() => {
		if (isFormReady === true) formRef.current.submit();
	}, [isFormReady]);

	const navigate = useNavigate();

	const [redirectToPayment, setRedirectToPayment] = React.useState(false);

	const handleValidation = () => {
		const now = new Date();
		const ts = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()} ${now.getHours()}:00:00`;

		if (order != null) {
			setRedirectToPayment(true);
			if (total === 0) navigate(routesDefinitions["PAYMENT_SUCCESS"].routerObj.path);
			switch (tunnel) {
				default:
				case 0:
					if (paymentMethod === 1) navigate(routesDefinitions["CHECK_SENDING"].routerObj.path);
					break;
				case 1:
					break;
				case 2:
					break;
			}
			return;
		}

		switch (tunnel) {
			default:
			case 0: {
				createSubscription({
					...variables,
					typepaiement: paymentMethod,
					ts,
				}).then(({ data, ...rest }) => {
					if (rest.isError === false) {
						setOrderInStore(data.commande);
						setRedirectToPayment(true);
						// if we pay via check.
						if (paymentMethod === 1) {
							navigate(routesDefinitions["CHECK_SENDING"].routerObj.path);
						}
					}
				});
				break;
			}
			case 1: {
				createGiftCard({
					...variables,
					typepaiement: paymentMethod,
					ts,
				}).then(({ data, ...rest }) => {
					if (rest.isError === false) {
						setOrderInStore(data.commande);
						setRedirectToPayment(true);
					}
				});
				break;
			}
			case 2: {
				activateGiftCard({
					...variables,
					typepaiement: paymentMethod,
					ts,
				}).then(({ data, ...rest }) => {
					if (rest.isError === false) {
						setOrderInStore(data.commande);
						if (total === 0) navigate(routesDefinitions["PAYMENT_VALIDATION"].routerObj.path);
						setRedirectToPayment(true);
					}
				});
				break;
			}
		}
	};

	return (
		<BasicScreen
			step={stepNb}
			nextPath={() => {
				if (!skipCreation) {
					handleValidation();
				}
			}}
			canGoNext={paymentMethod !== null || total === 0}
			nextStepButtonLabel={total === 0 ? "Terminer ma commande" : "Payer ma commande"}
		>
			{total > 0 ? (
				<>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 20,
							marginBottom: (theme) => theme.spacing(2),
						}}
					>
						{"Choisissez votre moyen de paiement :"}
					</Typography>
					<Grid container flexDirection={"column"} spacing={2}>
						<Grid item>
							<InexCard
								selected={isCB}
								sx={{
									paddingLeft: (theme) => theme.spacing(4),
									paddingRight: (theme) => theme.spacing(4),
									paddingTop: (theme) => theme.spacing(3),
									paddingBottom: (theme) => theme.spacing(3),
									"&:hover": {
										cursor: "pointer",
									},
								}}
								onClick={() => {
									setPaymentMethodInStore(2);
								}}
							>
								<TopBorder selected={isCB} />
								<Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} pt={1} pb={1}>
									<Grid container flexDirection={"column"}>
										<Grid
											item
											sx={{
												display: "flex",
												alignItems: "center",
												flexDirection: "row",
												justifyContent: "space-between",
												height: "35px",
											}}
										>
											<Typography
												variant={"h4"}
												textAlign={"left"}
												color={"secondary"}
												sx={{
													fontFamily: "Branding SemiBold",
													fontSize: 16,
												}}
											>
												{"Payer par carte bleue"}
											</Typography>
										</Grid>
										<Grid
											item
											container
											flexDirection={"row"}
											sx={{
												marginTop: (theme) => theme.spacing(2),
											}}
										>
											<img src={lockIcon} alt={"lockIcon"} style={{ marginRight: 10 }} />
											<Typography
												variant={"h4"}
												textAlign={"left"}
												color={"secondary"}
												sx={{
													fontFamily: "Branding SemiBold",
													fontSize: 16,
												}}
											>
												{"Paiement sécurisé"}
											</Typography>
										</Grid>
										<Grid
											item
											container
											flexDirection={"row"}
											sx={{
												marginTop: (theme) => theme.spacing(1),
											}}
										>
											<img src={VISA} alt={"paymentIcon-VISA"} style={{ marginRight: 10 }} />
											<img src={MCIcon} alt={"paymentIcon-MCIcon"} style={{ marginRight: 10 }} />
											<img src={AMEXIcon} alt={"paymentIcon-AMEXIcon"} style={{ marginRight: 10 }} />
											<img src={CBIcon} alt={"paymentIcon-CBIcon"} style={{ marginRight: 10 }} />
										</Grid>
									</Grid>
									<Grid item>
										<Radio
											color={"secondary"}
											checked={isCB}
											onClick={() => {
												setPaymentMethodInStore(2);
											}}
										/>
									</Grid>
								</Box>
							</InexCard>
						</Grid>

						{tunnel !== 2 && selectedBilling.titre !== "Mensuel" && (
							<Grid item>
								<InexCard
									selected={isCheck}
									sx={{
										paddingLeft: (theme) => theme.spacing(4),
										paddingRight: (theme) => theme.spacing(4),
										paddingTop: (theme) => theme.spacing(3),
										paddingBottom: (theme) => theme.spacing(3),
										"&:hover": {
											cursor: "pointer",
										},
									}}
									onClick={() => {
										setPaymentMethodInStore(1);
									}}
								>
									<TopBorder selected={isCheck} />
									<Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} pt={1} pb={1}>
										<Grid container flexDirection={"column"} spacing={2}>
											<Grid
												item
												sx={{
													display: "flex",
													alignItems: "center",
													flexDirection: "row",
													justifyContent: "space-between",
												}}
											>
												<Typography
													variant={"h4"}
													textAlign={"left"}
													color={"secondary"}
													sx={{
														fontFamily: "Branding SemiBold",
														fontSize: 16,
													}}
												>
													{"Payer par chèque"}
												</Typography>
											</Grid>
											<Grid
												item
												container
												flexDirection={"row"}
												sx={{
													marginTop: (theme) => theme.spacing(1),
												}}
											>
												<Typography
													variant={"h4"}
													textAlign={"left"}
													color={"grey.500"}
													sx={{
														fontFamily: "Branding SemiBold",
														fontSize: 16,
													}}
												>
													{
														"Votre commande sera activée après l’encaissement de votre chèque (prévoir un délai de 5 à 10 jours)"
													}
												</Typography>
											</Grid>
										</Grid>
										<Radio
											color={"secondary"}
											checked={isCheck}
											onClick={() => {
												setPaymentMethodInStore(1);
											}}
										/>
									</Box>
								</InexCard>
							</Grid>
						)}
					</Grid>

					{order !== null && paymentMethod === 2 && redirectToPayment === true && (
						<form
							ref={(element) => {
								if (element) {
									formRef.current = element;
									setTimeout(() => {
										setIsFormReady(true);
									}, 500);
								}
							}}
							method="POST"
							action={order.lienpaiement}
						>
							{order.infocb.map((element) => {
								return <input key={element.label} type="hidden" name={element.label} value={element.value} />;
							})}
						</form>
					)}
				</>
			) : (
				<InexCard>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 20,
						}}
					>
						{'Vérifiez vos informations et cliquez sur "Terminer ma commande" pour finaliser l\'activation de votre abonnement.'}
					</Typography>
				</InexCard>
			)}
		</BasicScreen>
	);
};

export default Payment;
