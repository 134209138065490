import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setTunnel } from "../store/reducers/subscribeSlice";
import { routesDefinitions } from "./RoutesDefinitions";

const JeMabonne = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	React.useEffect(() => {
		dispatch(setTunnel(0));
		navigate({
			pathname: routesDefinitions.SUBSCRIPTION_CHOICE.routerObj.path,
			search: window.location.search,
		});
	}, []);

	return null;
};

export default JeMabonne;
