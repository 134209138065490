import React from "react";
import { Box, Grid, IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";
import FloralBg from "../../resources/images/svg/FloralPage@1,5x.svg";
import logo from "../../resources/images/svg/logo.svg";
import userLogo from "../../resources/images/svg/user.svg";
import { useIsMobile } from "../../hooks/responsive";
import { getLoginRedirectURL, useIsLoggedIn } from "../../hooks/auth";
import SuccessfulConnexionSnackbar from "./SuccessfulConnexionSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { getTunnel } from "../../store/reducers/subscribeSlice";
import Cookies from "universal-cookie";
import { setAuthToken, setInreesId, setInreesToken } from "../../store/reducers/authSlice";

const Container = styled(Grid)(({ theme }) => {
	return {
		width: "100%",
		minHeight: "250px",
		position: "relative",
		display: "flex",
		zIndex: theme.zIndex.appBar,
	};
});

const BackgroundBox = styled(Box)((theme) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		background: "linear-gradient(90deg, #162941 0%, #8F6C9F 100%)",
		overflow: "hidden",
		zIndex: -1,
	};
});

export const GradientTypo = styled(Typography)(({ theme, disabled = false }) => {
	return {
		fontWeight: 700,
		background:
			disabled === false
				? "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)"
				: theme.palette.grey["400"],
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		overflow: "hidden",
		fontFamily: "Branding Bold",
		fontSize: "40px",
		display: "block",
		textTransform: "lowercase !important",
		"&:first-letter": {
			textTransform: "capitalize !important",
		},
	};
});

export const Subtitle = styled(Typography)((theme) => {
	return {
		textAlign: "left",
		color: "white",
		fontFamily: "Branding SemiBold",
		fontWeight: 600,
		fontSize: 20,
	};
});

const Header = () => {
	const isMobile = useIsMobile();

	const tunnel = useSelector(getTunnel);

	const isLoggedIn = useIsLoggedIn();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const redirectUrl = getLoginRedirectURL();

	const handleClick = React.useCallback(
		(event) => {
			if (isLoggedIn) {
				setAnchorEl(event.currentTarget);
			}
		},
		[isLoggedIn]
	);

	const handleClose = React.useCallback(() => {
		if (isLoggedIn) setAnchorEl(null);
	}, [isLoggedIn]);

	const headerTitle = React.useMemo(() => {
		if (tunnel === 2) return "J'active mon abonnement";
		if (tunnel === 1) return "J'offre un abonnement";
		if (tunnel === 3) return "Je me réabonne";
		return "Je m’abonne";
	}, [tunnel]);

	const dispatch = useDispatch();

	const logOut = () => {
		const cookies = new Cookies();
		cookies.remove("INEXPLORE_USER", {
			path: "/",
			domain: ".inexplore.com",
		});
		cookies.remove("INEXPLORE_TOKEN", {
			path: "/",
			domain: ".inexplore.com",
		});
		cookies.remove("INEXPLORE_ID", {
			path: "/",
			domain: ".inexplore.com",
		});
		dispatch(setInreesId(null));
		dispatch(setInreesToken(null));
		dispatch(setAuthToken(null));
		const redirectUrl = getLoginRedirectURL();
		window.location.href = redirectUrl;
	};

	return (
		<Container container px={isMobile ? 2 : 5} py={5}>
			<BackgroundBox>
				<img
					src={FloralBg}
					className=""
					alt="header-floral-bg"
					style={{
						float: "left",
					}}
				/>
			</BackgroundBox>
			<Grid container direction={"column"}>
				<Grid container direction={"row"} justifyContent={"space-between"} marginBottom={isMobile ? 3 : 5}>
					<Grid item justifyContent={"flex-start"} alignItems={"center"} display={"flex"}>
						<img src={logo} className="" alt="logo" style={{}} />
					</Grid>
					<Grid item display={"flex"}>
						{!isLoggedIn ? (
							<a href={redirectUrl}>
								<IconButton sx={{ width: 40, height: 40 }} size={"medium"}>
									<img src={userLogo} alt={"user-logo"} />
								</IconButton>
							</a>
						) : (
							<IconButton sx={{ width: 40, height: 40 }} size={"medium"} onClick={handleClick}>
								<img src={userLogo} alt={"user-logo"} />
							</IconButton>
						)}
						<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
							<MenuItem
								onClick={() => {
									handleClose();
									window.location.href = "https://my.inexplore.com/profil";
								}}
							>
								<Typography fontFamily={"Branding Medium"} color={"secondary"}>
									Mon profil
								</Typography>
							</MenuItem>
							<MenuItem onClick={logOut}>
								<Typography fontFamily={"Branding Medium"} color={"secondary"}>
									Me déconnecter
								</Typography>
							</MenuItem>
						</Menu>
					</Grid>
				</Grid>
				<Grid item container direction={"column"} px={isMobile ? 0 : 10}>
					<Grid item style={{ marginRight: "auto", maxWidth: "100%" }}>
						<GradientTypo variant={"h4"} textAlign={"left"}>
							{headerTitle}
						</GradientTypo>
					</Grid>
					<Grid item style={{ marginRight: "auto" }}>
						<Subtitle variant={"h5"}>Suivez le guide...</Subtitle>
					</Grid>
				</Grid>
			</Grid>
			<SuccessfulConnexionSnackbar />
		</Container>
	);
};

export default Header;
