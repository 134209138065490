import React from "react";
import { useNavigate } from "react-router";
import { routesDefinitions } from "./RoutesDefinitions";

const RedirectMobile = ({ deviceType, routeTo }) => {
	const navigate = useNavigate();
	React.useEffect(() => {
		let subscriptionId;
		if (routeTo === "inexplore") subscriptionId = 1;
		if (routeTo === "inexploreTV") subscriptionId = 2;

		navigate({
			pathname: routesDefinitions.SUBSCRIPTION_CHOICE.routerObj.path,
			search: `?selectedAbos=[${subscriptionId}]`,
		});
	}, [deviceType, routeTo]);

	return <></>;
};

export default RedirectMobile;
