import React from "react";
import { Box, Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	getApplyTip,
	getGiftMethod,
	getPromo,
	getSelectedAddress,
	getSelectedAddressCost,
	getSelectedBilling,
	getSelectedCountry,
	getSelectedSubscriptions,
	getTipAmount,
	getTipPeriod,
	getTunnel,
	setPromo,
} from "../store/reducers/subscribeSlice";
import { routesDefinitions } from "../routes/RoutesDefinitions";
import PriceFormater from "./PriceFormater";
import { useNavigate } from "react-router";
import { decodeString } from "../hooks/decodeString";
import InexButton from "./InexButton";
import { useStep } from "../hooks/useStep";
import { getGiftCardData } from "../store/reducers/giftCardSlice";
import InexTextField from "./InexTextField";
import { useLazyVerifPromoQuery } from "../store/api/subscribeTunnel";
import { getAuthToken, getInreesId } from "../store/reducers/authSlice";
import checkIcon from "../resources/images/svg/CheckIcon.svg";

const TunnelDetail = ({ ...props }) => {
	const tunnel = useSelector(getTunnel);

	const tunnelLabel = React.useMemo(() => {
		if (tunnel === 2) return "J'active mon abonnement";
		if (tunnel === 1) return "J'offre une carte-cadeau";
		if (tunnel === 3) return "Je me réabonne";
		return "Je m'abonne";
	}, [tunnel]);

	const navigate = useNavigate();

	return (
		<Grid container item direction={"column"} {...props}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Type d'abonnement"}
				</Typography>
				<Typography
					component={"div"}
					textAlign={"right"}
					onClick={() => {
						navigate(routesDefinitions["LANDING"].routerObj.path);
					}}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						color: (theme) => theme.palette.grey[500],
						textDecoration: "underline",
						"&:hover": {
							cursor: "pointer",
							color: (theme) => theme.palette.grey[400],
							transition: "color 0.4s",
						},
					}}
				>
					{"Modifier"}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
					}}
				>
					{tunnelLabel}
				</Typography>
			</Grid>
		</Grid>
	);
};

const CountryDetail = ({ ...props }) => {
	const selectedCountry = useSelector(getSelectedCountry);

	const navigate = useNavigate();

	return (
		<Grid container item direction={"column"}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Pays"}
				</Typography>
				<Typography
					component={"div"}
					textAlign={"right"}
					onClick={() => {
						navigate(routesDefinitions["COUNTRY_CHOICE"].routerObj.path);
					}}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						color: (theme) => theme.palette.grey[500],
						textDecoration: "underline",
						"&:hover": {
							cursor: "pointer",
							color: (theme) => theme.palette.grey[400],
							transition: "color 0.4s",
						},
					}}
				>
					{"Modifier"}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
					}}
				>
					{selectedCountry?.pays}
				</Typography>
			</Grid>
		</Grid>
	);
};

const SubscriptionsDetail = ({ canEdit = true, ...props }) => {
	const tunnel = useSelector(getTunnel);
	const giftCardData = useSelector(getGiftCardData);
	const selectedSubscriptions = useSelector(getSelectedSubscriptions);

	const navigate = useNavigate();

	return (
		<Grid container item direction={"column"}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Panier"}
				</Typography>
				{canEdit && (
					<Typography
						component={"div"}
						textAlign={"right"}
						onClick={() => {
							navigate(routesDefinitions["SUBSCRIPTION_CHOICE"].routerObj.path);
						}}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 16,
							color: (theme) => theme.palette.grey[500],
							textDecoration: "underline",
							"&:hover": {
								cursor: "pointer",
								color: (theme) => theme.palette.grey[400],
								transition: "color 0.4s",
							},
						}}
					>
						{"Modifier"}
					</Typography>
				)}
			</Grid>
			{tunnel !== 2
				? selectedSubscriptions?.map((sub, index) => {
						return (
							<Grid key={index} item container direction={"row"} justifyContent={"space-between"} mt={0.5}>
								<Grid item>
									<Typography
										component={"div"}
										textAlign={"left"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 16,
										}}
									>
										{sub.titre}
									</Typography>
								</Grid>
							</Grid>
						);
				  })
				: giftCardData?.commande?.details.map((element, index) => {
						return (
							<Grid key={index} item container direction={"row"} justifyContent={"space-between"} mt={0.5}>
								<Grid item>
									<Typography
										component={"div"}
										textAlign={"left"}
										color={"secondary"}
										sx={{
											fontFamily: "Branding SemiBold",
											fontSize: 16,
										}}
									>
										{element.abo.titre}
									</Typography>
								</Grid>
							</Grid>
						);
				  })}
		</Grid>
	);
};

const BillingDetail = ({ canEdit = true, ...props }) => {
	const selectedBilling = useSelector(getSelectedBilling);
	const tunnel = useSelector(getTunnel);
	const giftCardData = useSelector(getGiftCardData);

	const navigate = useNavigate();

	return (
		<Grid container item direction={"column"}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Facturation"}
				</Typography>
				{canEdit && (
					<Typography
						component={"div"}
						textAlign={"right"}
						onClick={() => {
							navigate(routesDefinitions["BILLING_CHOICE"].routerObj.path);
						}}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 16,
							color: (theme) => theme.palette.grey[500],
							textDecoration: "underline",
							"&:hover": {
								cursor: "pointer",
								color: (theme) => theme.palette.grey[400],
								transition: "color 0.4s",
							},
						}}
					>
						{"Modifier"}
					</Typography>
				)}
			</Grid>
			{(selectedBilling || (tunnel === 2 && giftCardData?.commande)) && (
				<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
					<Grid item>
						<Typography
							component={"div"}
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding SemiBold",
								fontSize: 16,
							}}
						>
							{tunnel !== 2 ? selectedBilling.titre : giftCardData?.commande.details[0].titre}
						</Typography>
						{tunnel !== 2 && selectedBilling.promo && (
							<Typography
								fontFamily={"Branding SemiBold"}
								sx={{
									fontSize: 14,
									color: (theme) => theme.palette.secondary.main,
								}}
							>
								{"Promo : " + (selectedBilling.promo && selectedBilling.promo !== "" ? selectedBilling.promo : "")}
							</Typography>
						)}
					</Grid>
					<Grid item>
						<PriceFormater
							price={tunnel !== 2 ? selectedBilling.montant : giftCardData?.commande.total}
							withTitle={false}
							period={tunnel !== 2 ? selectedBilling.titre : null}
							withTimeFrame={tunnel === 0}
							priceProps={{
								sx: {
									fontSize: 16,
								},
							}}
							timeFrameProps={{
								sx: { paddingBottom: 0, fontSize: 14 },
							}}
						/>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

const DeliveryDetail = ({ canEdit = false, ...props }) => {
	const giftMethod = useSelector(getGiftMethod);

	const navigate = useNavigate();

	return (
		<Grid container item direction={"column"} {...props}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Format"}
				</Typography>
				{canEdit === true && (
					<Typography
						component={"div"}
						textAlign={"right"}
						onClick={() => {
							navigate(routesDefinitions["DELIVERY_FORMAT"].routerObj.path);
						}}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 16,
							color: (theme) => theme.palette.grey[500],
							textDecoration: "underline",
							"&:hover": {
								cursor: "pointer",
								color: (theme) => theme.palette.grey[400],
								transition: "color 0.4s",
							},
						}}
					>
						{"Modifier"}
					</Typography>
				)}
			</Grid>
			<Grid item>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"secondary"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
					}}
				>
					{giftMethod === 0 ? "Par voie postale" : "Carte cadeau numérique"}
				</Typography>
			</Grid>
		</Grid>
	);
};

const AddressDetail = ({ canEdit = true, ...props }) => {
	const selectedAddress = useSelector(getSelectedAddress);
	const selectedAddressCost = useSelector(getSelectedAddressCost);

	const navigate = useNavigate();
	const tunnel = useSelector(getTunnel);

	const addressStr = decodeString(selectedAddress?.address);
	const country = decodeString(selectedAddress?.pays);
	const city = decodeString(selectedAddress?.ville);

	const complement1 = decodeString(selectedAddress?.complement1);
	const complement2 = decodeString(selectedAddress?.complement2);

	return (
		<Grid container item direction={"column"}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Adresse"}
				</Typography>
				{canEdit && (
					<Typography
						component={"div"}
						textAlign={"right"}
						onClick={() => {
							navigate(routesDefinitions["ADDRESS_CHOICE"].routerObj.path);
						}}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 16,
							color: (theme) => theme.palette.grey[500],
							textDecoration: "underline",
							"&:hover": {
								cursor: "pointer",
								color: (theme) => theme.palette.grey[400],
								transition: "color 0.4s",
							},
						}}
					>
						{"Modifier"}
					</Typography>
				)}
			</Grid>
			{selectedAddress && (
				<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
					<Grid item>
						<Typography
							component={"div"}
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding SemiBold",
								fontSize: 16,
							}}
						>
							{`${addressStr}`}
						</Typography>
						{selectedAddress.complement1 && selectedAddress.complement1 !== "" && (
							<Typography
								component={"div"}
								textAlign={"left"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding SemiBold",
									fontSize: 16,
								}}
							>
								{`${complement1}`}
							</Typography>
						)}
						{selectedAddress.complement2 && selectedAddress.complement2 !== "" && (
							<Typography
								component={"div"}
								textAlign={"left"}
								color={"secondary"}
								sx={{
									fontFamily: "Branding SemiBold",
									fontSize: 16,
								}}
							>
								{`${complement2}`}
							</Typography>
						)}
						<Typography
							component={"div"}
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding SemiBold",
								fontSize: 16,
							}}
						>
							{`${selectedAddress.cp} ${city}, ${country}`}
						</Typography>
					</Grid>
					<Grid item>
						{selectedAddressCost && tunnel !== 2 ? (
							<PriceFormater
								price={selectedAddressCost}
								withTitle={false}
								withTimeFrame={false}
								withSign={true}
								priceProps={{
									sx: {
										fontSize: 16,
									},
								}}
								timeFrameProps={{
									sx: { paddingBottom: 0, fontSize: 14 },
								}}
							/>
						) : null}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

const TipDetail = ({ canEdit = true, ...props }) => {
	const applyTip = useSelector(getApplyTip);
	const tipAmount = useSelector(getTipAmount);
	const tipPeriod = useSelector(getTipPeriod);
	const tunnel = useSelector(getTunnel);
	const giftCardData = useSelector(getGiftCardData);
	const selectedBilling = useSelector(getSelectedBilling);

	const navigate = useNavigate();

	return (
		<Grid container item direction={"column"}>
			<Grid item container direction={"row"} justifyContent={"space-between"} wrap={"nowrap"}>
				<Typography
					component={"div"}
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginRight: (theme) => theme.spacing(2),
					}}
				>
					{"Coup de pouce"}
				</Typography>
				{canEdit && (
					<Typography
						component={"div"}
						textAlign={"right"}
						onClick={() => {
							navigate(routesDefinitions["TIP_CHOICE"].routerObj.path);
						}}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 16,
							color: (theme) => theme.palette.grey[500],
							textDecoration: "underline",
							"&:hover": {
								cursor: "pointer",
								color: (theme) => theme.palette.grey[400],
								transition: "color 0.4s",
							},
						}}
					>
						{"Modifier"}
					</Typography>
				)}
			</Grid>
			<Grid item container direction={"row"} justifyContent={"space-between"} mt={0.5}>
				<Grid item>
					<Typography
						component={"div"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 16,
						}}
					>
						{"Valeur"}
					</Typography>
				</Grid>
				<Grid item>
					<PriceFormater
						price={applyTip === true ? tipAmount * 100 : 0}
						withTitle={false}
						period={tunnel !== 2 && tipPeriod === 1 ? "Mensuel" : "Annuel"}
						withTimeFrame={tipPeriod > 0 && applyTip === true}
						priceProps={{
							sx: {
								fontSize: 16,
							},
						}}
						timeFrameProps={{
							sx: { paddingBottom: 0, fontSize: 14 },
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const calculateTotalAmount = (montant = 0, selectedAddressCost, applyTip, tipAmount, promo = null) => {
	const val =
		parseInt(montant) + parseInt(selectedAddressCost ? selectedAddressCost : 0) - parseInt(promo?.montant ?? 0);
	return val + (applyTip ? tipAmount * 100 : 0);
};

const TotalPriceDetail = ({ currentStep, ...props }) => {
	const tunnel = useSelector(getTunnel);
	const giftCardData = useSelector(getGiftCardData);
	const selectedBilling = useSelector(getSelectedBilling);
	const selectedAddressCost = useSelector(getSelectedAddressCost);

	const tipStep = useStep("TIP_CHOICE");
	const paymentStep = useStep("PAYMENT");
	const addressStep = useStep("ADDRESS_CHOICE");

	const promo = useSelector(getPromo);

	const applyTip = useSelector(getApplyTip);
	const tipAmount = useSelector(getTipAmount);

	const total = React.useMemo(() => {
		let addressCost = 0;
		if (currentStep >= addressStep) addressCost = selectedAddressCost;
		if (tunnel === 2 && addressCost < 0) addressCost = 0;
		return calculateTotalAmount(
			tunnel !== 2 ? selectedBilling?.montant : 0,
			addressCost,
			currentStep >= tipStep ? applyTip : false,
			tipAmount,
			currentStep >= paymentStep ? promo : null
		);
	}, [
		selectedBilling,
		selectedAddressCost,
		applyTip,
		tipAmount,
		tipStep,
		paymentStep,
		currentStep,
		addressStep,
		tunnel,
		promo,
	]);

	if (!selectedBilling && tunnel !== 2) return null;

	return (
		<Box {...props}>
			<Divider variant={"fullWidth"} />
			{promo != null && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "nowrap",
						justifyContent: "space-between",
						alignItems: "center",
						paddingTop: (theme) => theme.spacing(3),
					}}
				>
					<Typography textAlign={"left"} fontSize={16} color={"secondary"} fontFamily={"Branding SemiBold"}>
						Code promo
					</Typography>
					<PriceFormater
						price={-1 * promo.montant}
						withTitle={false}
						withTimeFrame={false}
						withTaxes={false}
						withSign={true}
						priceProps={{
							sx: {
								fontSize: 16,
							},
						}}
					/>
				</Box>
			)}
			{tunnel === 2 && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "nowrap",
						justifyContent: "space-between",
						alignItems: "center",
						paddingTop: (theme) => theme.spacing(3),
					}}
				>
					<Typography textAlign={"left"} fontSize={16} color={"secondary"} fontFamily={"Branding SemiBold"}>
						Valeur carte cadeau :
					</Typography>
					<PriceFormater
						price={-1 * giftCardData?.commande.total}
						withTitle={false}
						withTimeFrame={false}
						withTaxes={false}
						withSign={true}
						priceProps={{
							sx: {
								fontSize: 16,
							},
						}}
					/>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					flexWrap: "nowrap",
					justifyContent: "space-between",
					alignItems: "center",
					paddingTop: (theme) => theme.spacing(tunnel == 2 ? 1 : 3),
				}}
			>
				<Typography textAlign={"left"} fontSize={20} color={"secondary"} fontFamily={"Branding Bold"}>
					Total
				</Typography>
				<PriceFormater price={total} withTitle={false} withTimeFrame={false} withTaxes={true} />
			</Box>
		</Box>
	);
};

const PromoCode = ({ ...props }) => {
	// NAMASTEINREESTV

	const [value, setValue] = React.useState("");
	const [error, setError] = React.useState(false);

	const [checkPromoCode] = useLazyVerifPromoQuery();

	
	const selectedSubscriptions = useSelector(getSelectedSubscriptions);
	const selectedAddress = useSelector(getSelectedAddress);
	const selectedBilling = useSelector(getSelectedBilling);
	const selectedAddressCost = useSelector(getSelectedAddressCost);
	const applyTip = useSelector(getApplyTip);
	const tipAmount = useSelector(getTipAmount);

	const promo = useSelector(getPromo);
	const dispatch = useDispatch();

	const authToken = useSelector(getAuthToken);
	const inreesId = useSelector(getInreesId);

	return (
		<Box {...props}>
			<InexTextField
				label={"Code promo"}
				value={promo ? promo.code : value}
				disabled={promo != null}
				onChange={(e) => {
					setValue(e.target.value);
				}}
				error={error !== false}
				helperText={error === true ? "Code invalide ou déjà utilisé." : (error !== false ? error : undefined)}
				formControlSx={{
					width: "100%",
				}}
				endAdornment={
					<InputAdornment position={"end"}>
						<InexButton
							text={"appliquer"}
							disabled={!value || value === "" || promo != null}
							variant={promo != null ? "icon" : "text"}
							icon={checkIcon}
							sx={{
								width: promo != null ? "50px" : "90px",
							}}
							onClick={() => {
								checkPromoCode({
									authToken,
									idinexplore: inreesId,
									codepromo: value,
									selectedSubscriptions,
									selectedBilling,
									selectedAddress
								}).then(({ data, ...rest }) => {
									setError(rest.isError);
									if (rest.isError === false) {
										const total = calculateTotalAmount(
											selectedBilling?.montant,
											selectedAddressCost,
											applyTip,
											tipAmount,
											data.promo
										);
										console.log(total);
										if (total >= 100) dispatch(setPromo(data.promo));
										else {
											setValue("");
											setError("Code promo non applicable.");
										}
									}
								});
							}}
						/>
					</InputAdornment>
				}
			/>
		</Box>
	);
};

// currentStep => 0 = CountryChoice / 1 = Subscription choice / 2 = BillingChoice / 3 = AddressChoice / 4 = TipChoice / 5 = Payment
const SubscriptionDetail = ({ currentStep = 0, nextPath, canGoNext = false, nextStepButtonLabel, ...props }) => {
	const navigate = useNavigate();

	const handleNextClicked = () => {
		if (typeof nextPath === "string") {
			navigate(nextPath);
		} else if (typeof nextPath === "function") nextPath();
	};

	const tunnel = useSelector(getTunnel);

	const countryStep = useStep("COUNTRY_CHOICE");
	const subscriptionStep = useStep("SUBSCRIPTION_CHOICE");
	const billingStep = useStep("BILLING_CHOICE");
	const deliveryStep = useStep("DELIVERY_FORMAT");
	const addressStep = useStep("ADDRESS_CHOICE");
	const tipStep = useStep("TIP_CHOICE");
	const paymentStep = useStep("PAYMENT");

	return (
		<Grid item xs={12} mt={0} rowSpacing={3} container direction={"column"} {...props}>
			<Typography
				variant={"h4"}
				textAlign={"left"}
				color={"secondary"}
				sx={{
					fontFamily: "Branding SemiBold",
					fontSize: 20,
				}}
			>
				{"Votre sélection"}
			</Typography>
			<TunnelDetail mb={2} />
			{currentStep >= paymentStep && <PromoCode pb={3} />}
			{currentStep > subscriptionStep && <TotalPriceDetail currentStep={currentStep} pb={4} />}
			{currentStep > countryStep && (
				<>
					<InexButton
						variant={"text"}
						text={nextStepButtonLabel ? nextStepButtonLabel : "Étape suivante"}
						disabled={canGoNext !== true}
						onClick={handleNextClicked}
						sx={{
							width: "100%",
							height: "50px",
							borderRadius: "25px",
							marginBottom: (theme) => theme.spacing(8),
						}}
					/>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 20,
						}}
					>
						{"Détails de votre sélection"}
					</Typography>
				</>
			)}
			{currentStep > countryStep && tunnel !== 2 && <CountryDetail />}
			{currentStep > countryStep && <SubscriptionsDetail canEdit={currentStep > subscriptionStep && tunnel !== 2} />}
			{currentStep > subscriptionStep && <BillingDetail canEdit={currentStep > billingStep && tunnel !== 2} />}
			{currentStep > billingStep && tunnel === 1 && <DeliveryDetail canEdit={currentStep > deliveryStep} />}
			{currentStep > (tunnel === 1 ? deliveryStep : billingStep) && (
				<AddressDetail canEdit={currentStep > addressStep} />
			)}
			{currentStep > addressStep && <TipDetail canEdit={currentStep > tipStep} />}
			{currentStep > subscriptionStep && <TotalPriceDetail currentStep={currentStep} pt={4} />}
			<InexButton
				variant={"text"}
				text={nextStepButtonLabel ? nextStepButtonLabel : "Étape suivante"}
				disabled={canGoNext !== true}
				onClick={handleNextClicked}
				sx={{
					width: "100%",
					height: "50px",
					borderRadius: "25px",
					marginTop: (theme) => theme.spacing(4),
				}}
			/>
		</Grid>
	);
};

export default SubscriptionDetail;
