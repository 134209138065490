import React from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useIsMobile } from "../hooks/responsive";
import { useIsLoggedIn } from "../hooks/auth";
import { useSelector } from "react-redux";
import { getTunnel } from "../store/reducers/subscribeSlice";

const steps = [
	{
		label: "Pays",
	},
	{
		label: "Panier",
	},
	{
		label: "Facturation",
	},
	{
		label: "Adresse",
	},
	{
		label: "Coup de pouce",
	},
	{
		label: "Paiement",
	},
];

const AboStepper = ({ currentStep = 0, ...props }) => {
	const isMobile = useIsMobile();

	const isLoggedIn = useIsLoggedIn();

	const tunnel = useSelector(getTunnel);

	const items = React.useMemo(() => {
		let tmp = JSON.parse(JSON.stringify(steps));
		if (isLoggedIn === false) tmp[3].label = "Connexion / Adresse";

		if (tunnel !== null && tunnel === 1) {
			tmp.splice(3, 0, {
				label: "Format",
			});
		}

		return tmp;
	}, [isLoggedIn, tunnel]);

	return (
		<Box sx={{ width: "100%" }}>
			<Stepper activeStep={currentStep} alternativeLabel>
				{items.map((item, index) => {
					const label = item.label;
					return (
						<Step
							key={label}
							sx={{
								"& .MuiStepLabel-iconContainer": {
									zIndex: 1,
								},
							}}
						>
							<StepLabel
								sx={{
									"& .MuiStepLabel-root .Mui-completed": {
										color: "primary.main", // circle color (COMPLETED)
									},
									"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
										color: "primary.main", // Just text label (COMPLETED)
									},
									"& .MuiStepLabel-root .Mui-active": {
										color: "grey.500", // circle color (ACTIVE)
									},
									"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
										color: "primary.main", // Just text label (ACTIVE)
									},
									"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
										fill: "grey.500", // circle's number (ACTIVE)
									},
								}}
							>
								{!isMobile || index === currentStep ? label : ""}
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</Box>
	);
};

export default AboStepper;
