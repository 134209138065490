import React from "react";
import { FilledInput, FormControl, InputLabel, styled, Typography } from "@mui/material";

const StyledTextField = styled(FilledInput, {
	shouldForwardProp: (prop) => !["InputLabelProps"].includes(prop),
})(({ theme, disabled }) => {
	return {
		border: `1px solid ${disabled !== true ? "#e2e2e1" : theme.palette.grey[300]}`,
		borderRadius: "8px",
		fontFamily: "Branding SemiBold",

		overflow: "hidden",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
		boxShadow: "none",

		"&:hover": {
			border: `1px solid ${theme.palette.primary.main}`,
			backgroundColor: "transparent",
		},
		"&:hover:before": {
			borderBottom: "none !important",
		},
		"&:focus": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
		"&:before": {
			display: "none",
		},
		color: disabled !== true ? theme.palette.secondary.main : theme.palette.grey[300],
		".MuiTextField-inputRoot:hover > fieldset": {
			borderColor: theme.palette.primary.main,
		},
		".MuiInputBase-root": {
			color: disabled !== true ? theme.palette.secondary.main : theme.palette.grey[300],
			backgroundColor: "transparent",
		},
		"&.MuiInputBase-root": {
			border: `1px solid ${disabled !== true ? "#e2e2e1" : theme.palette.grey[300]}`,
			color: disabled !== true ? theme.palette.secondary.main : theme.palette.grey[300],
			backgroundColor: "white",
		},
		"&.MuiInputBase-root:hover": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
		"&.MuiInputBase-root:focus": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
		".MuiInputBase-root:hover": {
			backgroundColor: "transparent",
		},
		".MuiInputBase-root::before": {
			display: "none",
		},
		".MuiInputBase-root:hover > label": {
			transform: "translate(14px, -5px) scale(0.75)",
		},
		"& + .MuiInputBase-popper .MuiAutocomplete-option": {
			color: theme.palette.secondary.main,
		},
	};
});

const InexTextField = ({
	disabled = false,
	label = "Label",
	value,
	formControlSx,
	helperText,
	error = false,
	...props
}) => {
	return (
		<FormControl
			sx={{
				border: "none",
				"&:hover": {
					border: "none",
				},
				...formControlSx,
			}}
			variant="outlined"
		>
			<InputLabel
				variant={"filled"}
				htmlFor="outlined-adornment-password"
				disabled={disabled}
				sx={{
					color: (theme) => `${theme.palette.secondary.main}${value === "" || !value ? "99" : ""}`,
				}}
			>
				{label}
			</InputLabel>
			<StyledTextField value={value} disabled={disabled} label={label} error={error} {...props} />
			{helperText && (
				<Typography variant={"caption"} color={error ? "error" : "secondary"} pl={1}>
					{helperText}
				</Typography>
			)}
		</FormControl>
	);
};

export default InexTextField;
