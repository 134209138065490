import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import InexCard from "../components/InexCard";
import InexTextField from "../components/InexTextField";

import img from "../resources/images/svg/landing/Joffre.svg";
import InexButton from "../components/InexButton";
import { useLazyGetCarteCadeauQuery } from "../store/api/subscribeTunnel";
import { useDispatch } from "react-redux";
import { setGiftCardData } from "../store/reducers/giftCardSlice";
import { routesDefinitions } from "./RoutesDefinitions";
import { useNavigate } from "react-router";
import { resetSubscriptionStore } from "../store/reducers/subscribeSlice";

const ActivateGift = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const [code, setCode] = React.useState("");
	const [error, setError] = React.useState(false);

	const [getGiftCardData] = useLazyGetCarteCadeauQuery();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setGiftCardDataInStore = (data) => {
		dispatch(setGiftCardData(data));
	};

	return (
		<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
			<Grid container px={isMobile ? 1 : 5} pt={isMobile ? 2 : 5} spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 20,
						}}
					>
						{"Activer mon abonnement"}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<InexCard
						sx={{
							padding: 0,
							paddingTop: 0,
							paddingBottom: 0,
						}}
					>
						<Grid container flexDirection={"row"}>
							{!isMobile && (
								<Grid item sm={4}>
									<Box
										sx={{
											width: "100%",
											maxHeight: 400,
											overflow: "hidden",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<img
											src={img}
											alt={"activate-gift-card-image"}
											style={{
												objectFit: "cover",
											}}
										/>
									</Box>
								</Grid>
							)}
							<Grid item xs={12} sm={8} p={isMobile ? 3 : 6} container justifyContent={"center"} alignItems={"center"}>
								<Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
									<Grid item xs={12}>
										<Typography
											variant={"h4"}
											textAlign={"left"}
											color={"secondary"}
											sx={{
												fontFamily: "Branding Bold",
												fontSize: 20,
											}}
										>
											{"Saisissez le code inscrit sur votre carte cadeau"}
										</Typography>
									</Grid>
									<Grid item xs={12} xl={12}>
										<InexTextField
											label={"Code d'activation"}
											value={code}
											onChange={(e) => {
												setCode(e.target.value);
											}}
											formControlSx={{
												width: "100%",
											}}
											error={error}
											helperText={error ? "Code invalide ou déjà utilisé." : null}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										xl={6}
									>
										<InexButton
											text={"Activer mon abonnement"}
											disabled={!code || code === ""}
											onClick={() => {
												const now = new Date();
												const ts = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()} ${now.getHours()}:00:00`;
												getGiftCardData({
													code,
													ts,
												}).then(({ data, ...rest }) => {
													if (rest.isError === false) {
														setGiftCardDataInStore(data);
														dispatch(resetSubscriptionStore());
														navigate(routesDefinitions["ADDRESS_CHOICE"].routerObj.path);
													} else {
														setError(true);
													}
												});
											}}
											sx={{
												width: "100%",
												height: "50px",
											}}
										/>
									</Grid>
									<Grid item xs={12} xl={6}>
										<InexButton
											text={"Retour"}
											textWithGradient={false}
											onClick={() => {
												navigate(routesDefinitions["LANDING"].routerObj.path);
											}}
											sx={{
												padding: (theme) => theme.spacing(2),
												width: "100%",
												height: "50px",
												background: "transparent",
												border: (theme) => `1px solid ${theme.palette.secondary.main}`,
												color: (theme) => theme.palette.secondary.main,
												"&:hover": {
													backgroundColor: "grey.300",
												},
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</InexCard>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ActivateGift;
