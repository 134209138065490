import React from "react";
import { routesDefinitions } from "./RoutesDefinitions";
import BasicScreen from "../components/BasicScreen";
import { useStep } from "../hooks/useStep";
import { Grid, Radio, Typography } from "@mui/material";
import InexCard from "../components/InexCard";
import { TopBorder } from "../components/billingChoice/BillingCard";
import { useDispatch, useSelector } from "react-redux";
import {
	getGiftEmail,
	getGiftFirstName,
	getGiftLastName,
	getGiftMethod,
	getGiftPhone,
	setGiftEmail,
	setGiftFirstName,
	setGiftLastName,
	setGiftMethod,
	setGiftPhone,
} from "../store/reducers/subscribeSlice";
import DeliveryStep from "../components/deliveryFormat/DeliveryStep";
import InexTextField from "../components/InexTextField";

const DeliveryFormat = () => {
	const stepNb = useStep("DELIVERY_FORMAT");

	const giftMethod = useSelector(getGiftMethod);

	const dispatch = useDispatch();
	const setGiftMethodInStore = (val) => {
		dispatch(setGiftMethod(val));
	};

	const giftFirstName = useSelector(getGiftFirstName);
	const setGiftFirstNameInStore = (val) => {
		dispatch(setGiftFirstName(val));
	};

	const giftLastName = useSelector(getGiftLastName);
	const setGiftLastNameInStore = (val) => {
		dispatch(setGiftLastName(val));
	};

	const giftEmail = useSelector(getGiftEmail);
	const setGiftEmailInStore = (val) => {
		dispatch(setGiftEmail(val));
	};
	const checkEmail = (val) => {
		const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
		return reg.test(val);
	};
	const [isEmailValid, setIsEmailValid] = React.useState(checkEmail(giftEmail));

	const giftPhone = useSelector(getGiftPhone);
	const setGiftPhoneInStore = (val) => {
		dispatch(setGiftPhone(val));
	};
	const checkPhoneNumber = (val) => {
		/*
		const reg =
			/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
		*/
		const reg =
			/^(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;

		return reg.test(val);
	};
	const [isPhoneValid, setIsPhoneValid] = React.useState(checkPhoneNumber(giftPhone));

	const canGoNext = React.useMemo(() => {
		if (giftMethod !== null) {
			if (giftMethod === 0) return true;
			else if (giftMethod === 1) {
				if (
					giftFirstName !== null &&
					giftFirstName !== "" &&
					giftLastName !== null &&
					giftLastName !== "" &&
					giftEmail !== null &&
					isEmailValid === true &&
					giftPhone !== null &&
					isPhoneValid === true
				)
					return true;
			}
		}
		return false;
	}, [giftMethod, giftFirstName, giftLastName, giftEmail, isEmailValid, giftPhone, isPhoneValid]);

	return (
		<BasicScreen step={stepNb} nextPath={routesDefinitions["ADDRESS_CHOICE"].routerObj.path} canGoNext={canGoNext}>
			<Typography
				variant={"h4"}
				textAlign={"left"}
				color={"secondary"}
				sx={{
					fontFamily: "Branding Bold",
					fontSize: 20,
				}}
			>
				{"Choisissez le mode d'envoi de la carte cadeau :"}
			</Typography>
			<Grid container spacing={3} my={3}>
				<Grid item xs={12} container>
					<InexCard
						selected={giftMethod === 0}
						sx={{
							padding: 0,
							paddingTop: 0,
							paddingBottom: 0,
						}}
						onClick={() => {
							setGiftMethodInStore(0);
						}}
					>
						<TopBorder selected={giftMethod === 0} />
						<Grid container>
							<Grid
								xs={2}
								md={1}
								item
								sx={{
									alignItems: "center",
									display: "flex",
									justifyContent: "center",
									backgroundColor: (theme) => theme.palette.grey["200"],
								}}
							>
								<Radio
									color={"secondary"}
									checked={giftMethod === 0}
									onClick={() => {
										setGiftMethodInStore(0);
									}}
								/>
							</Grid>
							<Grid
								xs={10}
								md={11}
								item
								sx={{
									display: "flex",
									flexDirection: "column",
									padding: (theme) => theme.spacing(4),
								}}
							>
								<Typography
									variant={"h4"}
									textAlign={"left"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding Bold",
										fontSize: 20,
									}}
								>
									{"Recevoir la carte-cadeau par voie postale"}
								</Typography>
								<Typography
									variant={"h4"}
									textAlign={"left"}
									color={"grey.500"}
									sx={{
										fontFamily: "Branding SemiBold",
										fontSize: 16,
										marginTop: (theme) => theme.spacing(2),
									}}
								>
									{
										"Avec ce mode d'envoi, vous recevrez la carte cadeau dans votre boite aux lettres, et vous pourrez ensuite la donner à son destinataire."
									}
								</Typography>
								{giftMethod === 0 && (
									<Grid container mt={1} spacing={2}>
										<Grid item xs={12}>
											<Typography
												variant={"h4"}
												textAlign={"left"}
												color={"secondary"}
												sx={{
													fontFamily: "Branding Bold",
													fontSize: 16,
												}}
											>
												{"Voici comment procéder :"}
											</Typography>
										</Grid>
										<DeliveryStep title={"Renseignez vos coordonnées postales en cliquant sur valider."} nb={1} />
										<DeliveryStep title={"Vous recevez la carte-cadeau dans votre boite aux lettres."} nb={2} />
										<DeliveryStep title={"Vous la donnez en main propre au destinataire du cadeau."} nb={3} />
									</Grid>
								)}
							</Grid>
						</Grid>
					</InexCard>
				</Grid>
				<Grid item xs={12}>
					<InexCard
						selected={giftMethod === 1}
						sx={{
							padding: 0,
							paddingTop: 0,
							paddingBottom: 0,
						}}
						onClick={() => {
							setGiftMethodInStore(1);
						}}
					>
						<TopBorder selected={giftMethod === 1} />
						<Grid container>
							<Grid
								xs={2}
								md={1}
								item
								sx={{
									alignItems: "center",
									display: "flex",
									justifyContent: "center",
									backgroundColor: (theme) => theme.palette.grey["200"],
								}}
							>
								<Radio
									color={"secondary"}
									checked={giftMethod === 1}
									onClick={() => {
										setGiftMethodInStore(1);
									}}
								/>
							</Grid>
							<Grid
								xs={10}
								md={11}
								item
								sx={{
									display: "flex",
									flexDirection: "column",
									padding: (theme) => theme.spacing(4),
								}}
							>
								<Typography
									variant={"h4"}
									textAlign={"left"}
									color={"secondary"}
									sx={{
										fontFamily: "Branding Bold",
										fontSize: 20,
									}}
								>
									{"Envoyer la carte-cadeau numérique par email"}
								</Typography>
								<Typography
									variant={"h4"}
									textAlign={"left"}
									color={"grey.500"}
									sx={{
										fontFamily: "Branding SemiBold",
										fontSize: 16,
										marginTop: (theme) => theme.spacing(2),
									}}
								>
									{
										"Avec ce mode d’envoi, le destinataire recevra la carte cadeau dans sa boite mail, où il retrouvera le code d’activation de son abonnement."
									}
								</Typography>
								{giftMethod === 1 && (
									<Grid container mt={1} spacing={2}>
										<Grid item xs={12}>
											<Typography
												variant={"h4"}
												textAlign={"left"}
												color={"secondary"}
												sx={{
													fontFamily: "Branding Bold",
													fontSize: 16,
												}}
											>
												{"Renseignez les informations de votre proche :"}
											</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<InexTextField
												label={"Prénom"}
												value={giftFirstName}
												onChange={(e) => {
													setGiftFirstNameInStore(e.target.value);
												}}
												formControlSx={{
													width: "100%",
												}}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<InexTextField
												label={"Nom"}
												value={giftLastName}
												onChange={(e) => {
													setGiftLastNameInStore(e.target.value);
												}}
												formControlSx={{
													width: "100%",
												}}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<InexTextField
												label={"Email"}
												value={giftEmail}
												onChange={(e) => {
													const isValid = checkEmail(e.target.value);
													setIsEmailValid(isValid);
													setGiftEmailInStore(e.target.value);
												}}
												helperText={
													isEmailValid === false && giftEmail !== "" ? "Email incorrect ou incomplet." : undefined
												}
												error={isEmailValid === false}
												formControlSx={{
													width: "100%",
												}}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<InexTextField
												label={"Téléphone"}
												value={giftPhone}
												onChange={(e) => {
													const isValid = checkPhoneNumber(e.target.value);
													setIsPhoneValid(isValid);
													setGiftPhoneInStore(e.target.value);
												}}
												helperText={
													isPhoneValid === false && giftPhone !== "" ? "Téléphone incorrect ou incomplet. ( ex: 0033656787654 )" : undefined
												}
												error={isPhoneValid === false}
												formControlSx={{
													width: "100%",
												}}
											/>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</InexCard>
				</Grid>
			</Grid>
		</BasicScreen>
	);
};

export default DeliveryFormat;
