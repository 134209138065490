import React from "react";
import { useDispatch } from "react-redux";
import { setTunnel } from "../store/reducers/subscribeSlice";
import { useNavigate } from "react-router";
import { routesDefinitions } from "./RoutesDefinitions";

const Offrir = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	React.useEffect(() => {
		dispatch(setTunnel(1));
		navigate({
			pathname: routesDefinitions.SUBSCRIPTION_CHOICE.routerObj.path,
			search: window.location.search,
		});
	}, []);

	return null;
};

export default Offrir;
