import React from "react";
import SubscriptionDetail from "../components/SubscriptionDetail";
import { Grid, Skeleton, Typography } from "@mui/material";
import InexButton from "../components/InexButton";
import BasicScreen from "../components/BasicScreen";
import {
	useRedirectToAlreadySubscribed,
	useRedirectToCountryChoice,
	useRedirectToLanding,
	useRedirectToSubscriptionChoice
} from "../hooks/subscription";
import { routesDefinitions } from "./RoutesDefinitions";
import { useGetCycleAbonnementQuery } from "../store/api/subscribeTunnel";
import { useDispatch, useSelector } from "react-redux";
import {
	getSelectedBilling,
	getSelectedCountry,
	getSelectedSubscriptions,
	getTunnel,
	setSelectedBilling,
	setPromo,
} from "../store/reducers/subscribeSlice";
import BillingCard from "../components/billingChoice/BillingCard";
import { useStep } from "../hooks/useStep";

const BillingChoice = ({ ...props }) => {
	useRedirectToLanding();
	useRedirectToCountryChoice();
	useRedirectToSubscriptionChoice();
	useRedirectToAlreadySubscribed();

	const stepNb = useStep("BILLING_CHOICE");

	const tunnel = useSelector(getTunnel);
	const selectedCountry = useSelector(getSelectedCountry);
	const selectedSubscriptions = useSelector(getSelectedSubscriptions);
	const { data, isFetching } = useGetCycleAbonnementQuery(
		{
			tunnel,
			zone: selectedCountry?.zone,
			selectedSubscriptions,
		},
		{
			skip: selectedSubscriptions == null || selectedCountry == null || tunnel == null,
		}
	);

	const [billingOptions, setBillingOptions] = React.useState(null);

	const dispatch = useDispatch();
	React.useEffect(() => {
		if (data) setBillingOptions(data.frequences);
		dispatch(setPromo(null));
	}, [data]);

	const selectedBilling = useSelector(getSelectedBilling);

	const setSelectedBillingVal = (value) => {
		dispatch(setSelectedBilling(value));
	};

	React.useEffect(() => {
		if (billingOptions) {
			// Check that the frequence is the same as the current selectedBilling as well as the "montant" because the user
			// can go back and change its subscriptionChoice, the price will change so we need to check both values.
			const billing = billingOptions.find(
				(e) => e.frequence === selectedBilling?.frequence && e.montant === selectedBilling?.montant
			);
			if (!billing) setSelectedBillingVal(null);
		}
	}, [billingOptions, selectedBilling]);

	return (
		<BasicScreen
			step={stepNb}
			nextPath={routesDefinitions[tunnel === 1 ? "DELIVERY_FORMAT" : "ADDRESS_CHOICE"].routerObj.path}
			canGoNext={!!selectedBilling}
		>
			<Typography
				variant={"h4"}
				textAlign={"left"}
				color={"secondary"}
				sx={{
					fontFamily: "Branding Bold",
					fontSize: 20,
				}}
			>
				{tunnel === 0
					? "Vous vous apprêtez à vous abonner, choisissez un cycle de facturation :"
					: "Sélectionnez la durée de l'abonnement :"}
			</Typography>
			<Grid container spacing={3} my={3} direction={"row"} wrap={"wrap"}>
				{isFetching ? (
					<>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"342px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"342px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"342px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
					</>
				) : (
					billingOptions?.map((element, index) => {
						const selected = element.frequence === selectedBilling?.frequence;

						return (
							<Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
								<BillingCard
									tunnel={tunnel}
									billingObj={element}
									selected={selected}
									onClick={() => {
										setSelectedBillingVal(element);
									}}
								/>
							</Grid>
						);
					})
				)}
			</Grid>
		</BasicScreen>
	);
};

export default BillingChoice;
