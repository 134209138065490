import { configureStore } from "@reduxjs/toolkit";
import { api } from "src/store/api/rtkApi";
import RootReducer from "src/store/root";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import expireReducer from "redux-persist-expire";
import { initialState } from "./reducers/subscribeSlice";

const persistConfig = {
	key: "root",
	storage,
	transforms: [
		expireReducer("subscribe", {
			expireSeconds: 3600 * 24,
			expiredState: initialState,
			autoExpire: true,
		}),
	],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store = configureStore({
	devTools: true,
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export const persistor = persistStore(store);
