import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import ChoiceCard from "../components/landing/ChoiceCard";
import { useIsLarge, useIsMobile, useIsTablet } from "../hooks/responsive";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setTunnel } from "../store/reducers/subscribeSlice";

import JeMabonnePic from "../resources/images/svg/landing/JeMabonne.svg";
import JeMabonnePicMobile from "../resources/images/svg/landing/JeMabonneMobile.svg";
import CartePic from "../resources/images/svg/landing/Carte.svg";
import CartePicMobile from "../resources/images/svg/landing/CarteMobile.svg";
import JoffrePic from "../resources/images/svg/landing/Joffre.svg";
import JoffrePicMobile from "../resources/images/svg/landing/JoffreMobile.svg";
import { routesDefinitions } from "./RoutesDefinitions";
import GoogleTagManager from "../components/seo/GoogleTagManager";

const Landing = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const isLarge = useIsLarge();

	// TODO : add to store the card that was clicked, it will define the "tunnel" the user is in.

	const navigate = useNavigate();

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setTunnel(null));
	}, []);

	const handleClick = (tunnel) => {
		dispatch(setTunnel(tunnel));
		if (tunnel === 2) {
			navigate(routesDefinitions["ACTIVATE_CARD"].routerObj.path);
			return;
		}
		navigate(routesDefinitions["COUNTRY_CHOICE"].routerObj.path);
	};

	return (
		<div>
			<Grid
				container
				direction={isMobile ? "column" : "row"}
				width={"100%"}
				height={"100%"}
				columnSpacing={isMobile ? 0 : isLarge ? 10 : 7}
				rowSpacing={isMobile ? 4 : 7}
				paddingLeft={isMobile ? 2 : isLarge ? 13 : 3}
				paddingRight={isMobile ? 2 : isLarge ? 20 : 10}
				m={0}
				marginBottom={12}
				columns={13}
			>
				<Grid item xs={13} md={9}>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: isMobile ? 20 : 24,
						}}
					>
						{"Sélectionnez votre solution d'abonnement"}
					</Typography>
				</Grid>
				{!isTablet && (
					<Grid item md={4}>
						<Typography
							variant={"h4"}
							textAlign={"center"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding Bold",
								fontSize: 24,
							}}
						>
							{"Vous avez une carte-cadeau"}
						</Typography>
					</Grid>
				)}
				<Grid item xs={13} md={4}>
					<ChoiceCard
						title={"Je m'abonne"}
						image={JeMabonnePic}
						imageMobile={JeMabonnePicMobile}
						onClick={(event) => {
							handleClick(0);
						}}
					/>
				</Grid>
				<Grid item xs={13} md={4}>
					<ChoiceCard
						title={"J'offre une carte-cadeau"}
						image={CartePic}
						imageMobile={CartePicMobile}
						onClick={(event) => {
							handleClick(1);
						}}
					/>
				</Grid>
				<Grid
					item
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: (theme) => (isTablet ? 0 : theme.spacing(5)),
						paddingBottom: (theme) => (isTablet ? 0 : theme.spacing(5)),
					}}
					md={1}
				>
					<Divider
						flexItem
						orientation={isTablet ? "horizontal" : "vertical"}
						variant={isTablet ? "fullwidth" : "default"}
						sx={{
							height: "100%",
							width: isMobile ? "80%" : undefined,
						}}
					/>
				</Grid>
				{isTablet && (
					<Grid item xs={13}>
						<Typography
							variant={"h4"}
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding Bold",
								fontSize: isMobile ? 20 : 24,
							}}
						>
							{"Vous avez une carte-cadeau"}
						</Typography>
					</Grid>
				)}
				<Grid item xs={13} md={4}>
					<ChoiceCard
						title={"J'active ma carte-cadeau"}
						image={JoffrePic}
						imageMobile={JoffrePicMobile}
						onClick={(event) => {
							handleClick(2);
						}}
					/>
				</Grid>
			</Grid>
			<GoogleTagManager  />
		</div>
	);
};

export default Landing;
