import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "giftCard",
	initialState: {
		giftCardData: null,
	},
	reducers: {
		setGiftCardData(state, action) {
			state.giftCardData = action.payload;
		},
	},
});

export default slice.reducer;
export const { setGiftCardData } = slice.actions;

export const getGiftCardData = (state) => state.giftCard.giftCardData;
